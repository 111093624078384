import { defineStore } from "pinia";
import { shipmentService, redPointService } from "@/_services";

export const shipmentListStore = defineStore("shipmentsList", {
  state: () => ({
    reload: false,
    shipments: null,
    error: null,
    shipmentsLabel: null,
    shipmentTracking: null,
    shipmentAsExcel: null,
    points: null,
    canceledShipments:null,
    customFilterDate: null
  }),
  actions: {
    setCustomFilterDate(value){
      this.customFilterDate= value;
    },
    getShipments(pagination, filters, sort) {
      this.reload = false
      shipmentService
        .getAllShipments(pagination, filters, sort)
        .then((results) => (this.shipments = results))
        .catch((reason) => (this.error = reason));
    },
    getShipmentLabel(listId) {
      shipmentService
        .getShipmentLabel(listId)
        .then((results) => (this.shipmentsLabel = results))
        .catch((reason) => (this.error = reason));
    },
    cancelMultipleShipments(body){
      this.error= null;
      shipmentService
        .cancelMultiShipments(body)
        .then((results) => (this.canceledShipments = results))
        .catch((reason) => {
          this.canceledShipments= null;
          this.error = reason;
        });
    },
    getPoints(pagination, filters, sort) {
      this.points=null;
      redPointService
        .getPoints(pagination, filters, sort)
        .then((results) => (this.points = results))
        .catch((reason) => {
          this.points=null;
          this.error = reason
        });
    },
    getShipmentTracking(shipmentId) {
      this.shipmentTracking= null;
      shipmentService
        .getShipmentTracking(shipmentId)
        .then((results) => (this.shipmentTracking = results))
        .catch((reason) => {
          this.shipmentTracking= null;
          this.error = reason
        });
    },
    getShipmentsAsExcel(query, sort) {
      shipmentService
        .getShipmentsAsExcel(query, sort)
        .then((results) => (this.shipmentAsExcel = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
