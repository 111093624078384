<script setup>
const emit = defineEmits(["change"]);
import {ref} from 'vue';
import { argonTableStore } from "@/_store/argonTableStore";
import DateCustomFilter from "./DateCustomFilter.vue";
const showModalDate= ref(false);
const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  divider: {
    type: Boolean,
    default: false,
  },
});
function customDate(value, isActive){
  showModalDate.value = !showModalDate.value
  if(isActive){
    value.storage.setCustomFilterDate(null);
    change(value, isActive);
  }
}
function closeDateFilter() {
  showModalDate.value = false;
};
function filterDateAccepted(res){
    res.value.storage.setCustomFilterDate(res.newValue)
    res.value.value= res.newValue;
    change(res.value, false, res.label);
    closeDateFilter()
}
function change(value, isActive, customLabel='') {
  let label= ''
  if(value.isCustomDate){
    label= customLabel
  }else
    label=value.label

  let contract= {
    description: props.filter.label,
    field: props.filter.field,
    multi: props.filter.multi,
    label: label,
    value: value.value,
    active: !isActive,
    isDate: props.filter.isDate
  }

  emit("change", contract );
}
const tableStore = argonTableStore();

function isActive(option) {
  const filter = tableStore.filters.find(
    (filter) => filter.field == props.filter.field
  );

  if (filter) {
    if (props.filter.multi) {
      if (filter?.value?.$in?.find((value) => option.value == value)) return true;
    }else if(option.isCustomDate) {
      return filter?.value?.$gte ? true : false;
    }
    else if (filter.text == option.label) return true;
  }
  return false;
}
</script>

<template>
  <li v-for="(option, key) of filter.options" :key="key">
    <a
    v-if="option.isCustomDate"
      class="dropdown-item border-radius-md"
    >
    <div @click="customDate(option, isActive(option))">
      <i v-if="isActive(option)" class="fa fa-check me-sm-2"></i>
      <span v-else class="ms-4"></span>
      {{ option.label }}
    </div>
      <date-custom-filter v-if="showModalDate" :value="option" @success="filterDateAccepted"></date-custom-filter>
    </a>
    
    <a 
      v-else
        class="dropdown-item border-radius-md"
        @click="change(option, isActive(option))"
    >
      <i v-if="isActive(option)" class="fa fa-check me-sm-2"></i>
      <span v-else class="ms-4"></span>
      {{ option.label }}
    </a>
  </li>
  <li v-if="divider">
    <hr class="dropdown-divider" />
  </li>
</template>