<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import AutoComplete from "@/components/auto-complete/AutoComplete.vue";
import { shipmentListStore } from "@/_store/shipmentStore";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ArgonInput from "@/components/ArgonInput.vue"
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import ups from "@/assets/img/logos/partners/ups.svg";
import seur from "@/assets/img/logos/partners/seur.png";
import propio from "@/assets/img/logos/logo.svg";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import { userStore } from "@/_store/userStore";
const userStores = userStore();

const localValue = ref({
  point_code: "",
  observations: ""
});

const iconAutoComplete= ref("");
const text = ref("")
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {
        point_code: "",
        observations: ""
      }
    },
  },
  courier: {
    type: String,
    default: "",
  },
});

const dataSuggestions = ref([])
const shipmentStore = shipmentListStore();

const rules = {
  point_code: {
    required: helpers.withMessage("Seleccione un punto de red.", required),
  }
};

const v$ = useVuelidate(rules, localValue);

let pagination=false;
function getPoints(text,page) {
  if(page==1){
    pagination=true;
  }else pagination =false;
  let req = text!=""?{courier: props.courier, $or: [{ name: { $regex: text, $options: "i" } },{ 'address.postal_code': { $regex: text, $options: "i" } }]}:{courier: props.courier};
  shipmentStore.getPoints(
    { page: page, limit: 80 },
    req,
    { courier: 1, 'address.postal_code': 1 }
  );
}
function getImage(item){
  if (item.courier==='CEX') return cex;
  if(item.courier==='CTT') return ctt;
  if(item.courier==='UPS') return ups;
  if(item.courier==='SEUR') return seur;

  return beeLogo.value;
}

function selectedAutocomplete(value) {
  localValue.value.point_code = value.code;
  text.value = value.name;
  iconAutoComplete.value=getImage(value);
  emit("update:modelValue", localValue.value)
}

onMounted(() => {
  localValue.value = props.modelValue;
});

async function validateOfficeSolutions() {
  v$.value.$touch();
  await v$.value.$validate();
  return v$.value.$errors;
}

function resetModel() {
  text.value = "";
  localValue.value = {
    point_code: "",
    observations: ""
  };
  v$.value.$reset();
}

const beeLogo= computed(() => isAgency.value ? propio_agency : propio);
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");

watch(
  () => shipmentStore.points,
  (value) => {
    if(value && value.data.length > 0){
      if(pagination)
        dataSuggestions.value =[];
      dataSuggestions.value = [...dataSuggestions.value, ...value.data];
    }
  },
);
defineExpose({
  validateOfficeSolutions,
  resetModel
});
</script>

<template>
  <div class="tab-content">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-2">
            <label class="col-form-label required">Punto de red</label>
          </div>
          <div class="col-10">
            <auto-complete id="confTextOffice" :error="v$.point_code.$error" v-model="text"
              :data-suggestions="dataSuggestions" :item-text="['address.postal_code','name']" separator=" - " size="default"
              @text-changed="getPoints" @page-changed="getPoints" :icon-selected="iconAutoComplete" @selected-change="selectedAutocomplete"
              menu-width="400">
              <template #icon="{item}">
                <img :src="getImage(item)" style="margin-right:5px; width: 16px; height: 16px; border-radius: 50%; object-fit: cover;" />
              </template>
            </auto-complete>
          </div>
        </div>
        <div v-if="courier!='CTT'" class="row">
          <div class="col-2">
            <label class="col-form-label">Observaciones</label>
          </div>
          <div class="col-10">
            <argon-input id="observations" :max-length="40" v-model="localValue.observations" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tab-content {
  min-height: 57px;
}
</style>
