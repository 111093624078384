<script setup>
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import { computed, ref } from "vue";
import ResumeAddress from "./ResumeAddress.vue";
import moment from "moment";
import { userStore } from "@/_store/userStore";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import Tracking from "@/components/Tracking.vue";

const userStores = userStore();
defineProps({
  shipment: {
    type: Object,
    default: () => { },
  }
});
let currentTab = ref("shipment");
const tabs = ref([
  { label: "Envío", value: "shipment" },
  { label: "Seguimiento", value: "tracking" },
]);


function getWeightBultos(packages) {
  var result = packages.reduce(function (acc, packages) {
    return acc + packages.weight;
  }, 0);
  return result;
}
const isAgency = computed(() => userStores.userSigned?.portalType==='AGENCY');

</script>
<template>
  <div class="row spacing" v-if="isAgency">
    <div class="col">
      <div class="card">
        <div class="card-header">Cliente</div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Nombre</label>
            </div>
            <div class="col-8">
              <span>{{ shipment.customer.code }} - {{ shipment.customer.contact.name }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Email</label>
            </div>
            <div class="col-8">
              <span>{{ shipment.customer.contact.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row spacing">
    <div class="col">

    <div class="card">
      <div class="card-body " style="min-height: 290px !important">
        <tabs-bootstrap v-model="currentTab" :tabs="tabs" />
        <div v-if="currentTab === 'shipment'">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Fecha</label>
            </div>
            <div class="col-8">
              <span>{{
                moment(shipment.date).format("DD/MM/YYYY")
                }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Localizador</label>
            </div>
            <div class="col-8">
              <span>{{ shipment.locator }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Referencia</label>
            </div>
            <div class="col-8">
              <span>{{ shipment.reference }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Bultos</label>
            </div>
            <div class="col-8">
              <span>{{ shipment.packages.length }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Peso</label>
            </div>
            <div class="col-8">
              <span>{{ getWeightBultos(shipment.packages) }}Kg</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Estado</label>
            </div>
            <div class="col-8">
              <status-badge class="badge badge-md" :item="shipment.status" :tooltip="shipment.errors"></status-badge>
            </div>
          </div>
        </div>

        <tracking 
          v-if="currentTab == 'tracking'"
          style="max-height: 220px; overflow-y: auto; overflow-x: hidden;"
          :id-shipment="shipment._id"
          :url="shipment.uri_tracking"
          :courier="shipment.courier"
        />
      </div>
    </div>
  </div>

  </div>
  <div class="row spacing">
    <resume-address :address="shipment.pickup" title="Recogida" />
  </div>
  <div class="row spacing">
    <resume-address :address="shipment.delivery" title="Entrega" />
  </div>
</template>