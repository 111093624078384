<script setup>
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import Tracking from "@/components/StopTracking.vue";
import TabsBootstrap from "@/components/TabsBootstrap.vue";

import moment from "moment";
import { userStore } from "@/_store/userStore";
import { computed,ref } from "vue";

const userStores = userStore();
defineProps({
  stop: {
    type: Object,
    default: () => { },
  }
});
let currentTab = ref("stop");
const tabs = ref([
  { label: "Recogida", value: "stop" },
  { label: "Seguimiento", value: "tracking" },
]);
const isAgency = computed(() => userStores.userSigned?.portalType==='AGENCY');
</script>
<template>
  <div class="row spacing" v-if="isAgency">
    <div class="col">
      <div class="card">
        <div class="card-header">Cliente</div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Nombre</label>
            </div>
            <div class="col-8">
              <span>{{ stop.customer.code }} - {{ stop.customer.contact.name }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="col-form-label">Email</label>
            </div>
            <div class="col-8">
              <span>{{ stop.customer.contact.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row spacing">
    <div class="col">
      <div class="card">
        <div class="card-body" style="min-height: 290px !important">
          <tabs-bootstrap v-model="currentTab" :tabs="tabs" />
          <div v-if="currentTab === 'stop'">
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Fecha</label>
              </div>
              <div class="col-8">
                <span>{{
                  moment(stop.date).format("DD/MM/YYYY")
                  }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Ticket</label>
              </div>
              <div class="col-8">
                <span>{{ stop.number }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Tipo</label>
              </div>
              <div class="col-8">
                <span>{{ stop.type }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Courier</label>
              </div>
              <div class="col-8">
                <partner-avatar :item="stop.courier"></partner-avatar>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Dirección</label>
              </div>
              <div class="col-8">
                <span>{{ stop.address.postal_code }} -
                  {{ stop.address.city }} -
                  {{ stop.address.text }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Estado</label>
              </div>
              <div class="col-8">
                <status-badge class="badge badge-md" :item="stop.status" :tooltip="stop.errors"></status-badge>
              </div>
            </div>
          </div>
          <tracking
            v-if="currentTab == 'tracking'"
            style="max-height: 220px; overflow-y: auto; overflow-x: hidden;"
            :id-stop="stop._id"
            :courier="stop.courier"
          />
        </div>
      </div>
    </div>
  </div>
  
</template>