<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import { ref, onMounted } from "vue";
import { dateUtils } from "../../utils/dates";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
const emits = defineEmits(["success"]);

onMounted(() => {
  
});
const props = defineProps({
  value: {
    type: Object,
    required: true
  },
});
const range= ref({
  start: null,
  end: null,
});
const rules = {
  start: { required: helpers.withMessage("Fecha inicio es requerido", required) },
  end: { required: helpers.withMessage("Fecha fin es requerido", required) },
};
const v$ = ref(useVuelidate(rules, range));

async function accept(){
  const isValid = await v$.value.$validate()
  if (!isValid) return

  let end= moment(range.value.end).endOf('day');
  const newValue={$gte:  dateUtils.formatDate(range.value.start,"YYYY-MM-DD HH:mm") , $lte: dateUtils.formatDate(end,"YYYY-MM-DD HH:mm")};
  const label= `Desde: ${dateUtils.formatDate(range.value.start, props.value.formatDate)} Hasta: ${dateUtils.formatDate(end, props.value.formatDate)}`
  emits("success", {value:props.value, newValue, label})
}
</script>

<template>
  <div class="date-custom pt-2">
    <argon-input 
      id="custom-date-start" 
      v-model="range.start"  type="date" :is-required="true"  :error="v$.start.$error" />
    <argon-input 
      id="custom-date-end"
      v-model="range.end"  type="date" :is-required="true" :error="v$.end.$error" />
    <a  class=" btn-link ms-5" @click="accept" > Aceptar </a>
  </div>
</template>
