<script setup>
import { onMounted, watch, ref, computed } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import StatusBadge from "@/components/badges/StopStatus.vue";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { useRouter } from "vue-router";
import { userStore } from "@/_store/userStore";
import { ownPickupListStore } from "@/_store";
import { argonTableStore } from "@/_store/argonTableStore";
import { estados } from "@/maestros/stopStatus";
import {defaultFilterDates} from "@/maestros/defaultDatesValues";
import moment from "moment";

const snackbar = ref(null);
const fieldFilters = computed(() => {
  const baseFilters = [
    {
      field: "number",
      description: "Número",
    },
    {
      field: "address_text",
      description: "Dirección",
    },
  ];
  if (isAgency.value) {
    baseFilters.push({
      field: "customer.code",
      description: "Cliente Código",
    }, {
      field: "customer.contact.name",
      description: "Cliente Nombre",
    });
  }

  return baseFilters;
});
const ownPickupsStore = ownPickupListStore();

const customFilterDate= ref(ownPickupsStore.customFilterDate);
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: estados,
  },
  {
    field: "date",
    label: "Fecha",
    multi: false,
    isDate: true,

    options: [
      ...defaultFilterDates,
      { label: "Personalizado", value: customFilterDate, isCustomDate: true, storage: ownPickupsStore, formatDate:  "DD/MM/YYYY"}
    ],
  },
];
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();

const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");

if (isAgency.value) {
  defaultFilters.splice(1, 0 , {
    field: "priority",
    label: "Prioridad",
    multi: true,
    options: [
      { label: "Prioridad Alta", value: 3 },
      { label: "Prioridad Media", value: 2 },
      { label: "Prioridad Baja", value: 1 },
    ],
  });
}
const columns = ref([
  {
    header: "Número",
    field: "number",
    width: "w-10",

    sort: false,
  },
  {
    header: "Fecha",
    field: "date",
    width: "w-7",
    sort: true,
  },
  {
    header: "Dirección",
    field: "address_text",
    sort: false,
  },
  {
    header: "Horario",
    field: "schedules_text",
    sort: false,
  },
  {
    header: "Estado",
    field: "status",
    sort: false,
    position: "center",
    width: "w-10",
  }
]);
onMounted(() => {
  tableStore.clear();
  const ownPickupFilters = userStores.filtersPage.stopOwnPickups?.filters || [];
  const ownPickupFiltersFavorite = userStores.filtersPage.stopOwnPickups?.filtersFavorite || [];
  if (isAgency.value) {
    loadAgencyConfigs();
  }
  tableStore.changeFilters(ownPickupFilters);
  tableStore.addFavorite(ownPickupFiltersFavorite);
  if (reload.value) {
    setTimeout(() => {
      reloadPage()
    }, 700)
  }
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.stopOwnPickups = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  ownPickupsStore.getOwnPickups(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function exportData() {
  ownPickupsStore.getOwnPickupsAsExcel(tableStore.query, tableStore.sort);
}

function makeExport(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function getPriority(priority) {
  if (priority === undefined) {
    return ''
  }
  switch (priority) {
    case 3:
      return { icono: 'fa fa-arrow-up text-xxs', tooltip: "Alta" };
    case 2:
      return { icono: 'fa fa-arrow-right text-xxs', tooltip: "Media" };
    case 1:
      return { icono: 'fa fa-arrow-down text-xxs', tooltip: "Baja" };
    default:
      return { icono: '', tooltip: '' };
  }
}
function loadAgencyConfigs() {
  const newHeaders = [
    {
    header: "Prioridad",
    field: "priority",
    position: "center",
    sort: true,
    },
    {
      header: "Cliente",
      field: "customer.contact.name",
      width: "w-10",
      sort: false,
      position: "start"
    }
  ]
  const courierHeader = {
    header: "Partner",
    field: "courier",
    sort: false,
    position: "center",
  };

  columns.value.splice(2, 0, ...newHeaders);
  columns.value.splice(6, 0, courierHeader);
}
watch(
  () => ownPickupsStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => ownPickupsStore.ownPickupsAsExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
function onClick(val) {
  let url = '';
  if(isAgency.value) url = `/pickup/own/${val._id}`;
  else url = `/own-pickup/${val._id}`;
  router.push(url);
}
const closeSnackbar = () => {
  snackbar.value = null;
};

const reload = computed({
  get() {
    return ownPickupsStore.reload
  },
})
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table v-if="ownPickupsStore.ownPickups" :columns="columns" :items="ownPickupsStore.ownPickups"
        :field-filters="fieldFilters" :default-filters="defaultFilters" :show-importar="false" :show-new-button="false"
        :show-check-input="false" @on-click="onClick" @filter-changed="reloadPage" @page-changed="reloadPage"
        @sort-changed="reloadPage" @on-reload="reloadPage" @export-data="exportData">
        <template #default="{ item }">
          <argon-td :position="getPosition('number')" :text-primary="item.number"></argon-td>
          <argon-td :position="getPosition('date')" :text-secondary="moment(item.date).format('DD/MM/YYYY')"></argon-td>
          <argon-td v-if="isAgency"  v-tooltip :data-bs-title="getPriority(item.priority).tooltip" :position="getPosition('priority')">
            <i v-if="item.priority != undefined" :class="getPriority(item.priority).icono"></i>
          </argon-td>
          <argon-td v-if="isAgency" :position="getPosition('customer.contact.name')" :text-primary="item.customer.code"
            :text-secondary="item.customer.contact.name"></argon-td>
          <argon-td :position="getPosition('address_text')" :text-secondary="item.address_text"
            max-width="130px"></argon-td>
          <argon-td :position="getPosition('schedules_text')" :text-secondary="item.schedules_text"></argon-td>
          <argon-td v-if="isAgency" :position="getPosition('courier')">
            <partner-avatar :item="item.courier"></partner-avatar>
          </argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge v-if="item && item.error && item.error.length > 0" class="badge badge-md" :item="item.status"
              :tooltip="item.error"></status-badge>
            <status-badge v-else class="badge badge-md" :item="item.status"
              :tooltip="item.status_description"></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>