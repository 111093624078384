<script setup>
import { ref, watch, onMounted } from "vue";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  header: {
    type: Boolean,
    default: true,
  },
  sorter: {
    type: Boolean,
    default: true,
  },
});
const headers = ref([
  {
    header: "Concepto",
    field: "concept.code",
    sort: false,
  },
  {
    header: "Descripción",
    field: "description",
    sort: false,
  },
  {
    header: "Cantidad",
    field: "quantity",
    position: "end",
    sort: false,
  },
  {
    header: "UM",
    field: "unit_measurement",
    sort: false,
  },
  
  {
    header: "Precio",
    field: "customer_cost.unit_price",
    width: "w-20",
    sort: false,
  },
  {
    header: "Importe",
    field: "customer_cost.amount",
    width: "w-20",
    sort: false,
  },
]);
const tabs = ref([
  { label: "Inicial", value: "initial" },
]);
const currentTab = ref("initial");
const listTable = ref(null);
const model = ref(props.modelValue);
const valuation = ref({});

onMounted(() => {
  if(props.sorter)
  {
    tabs.value.push({ label: "Sorter", value: "sorter" });
    tabs.value.push({ label: "Beneficiosa", value: "profitable" });
  }    
  getList(currentTab.value);
});

function findValuation(type) {
  return (
    model.value.valuations?.find((valuation) => valuation.type === type) || {}
  );
}

function getList(tab) {
  valuation.value = findValuation(tab);
  listTable.value = { data: valuation.value.lines || [] };
}

function getPosition(field) {
  const column = headers.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

watch(
  () => currentTab.value,
  (newVal) => {
    if (newVal) {
      getList(newVal);
    }
  }
);
watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal) {
      model.value = newVal;
    }
  }
);
</script>
<template>
  <tabs-bootstrap v-model="currentTab" :tabs="tabs" style-nav="nav-underline" />
  <simple-table
    v-if="listTable && listTable.data && listTable.data.length > 0"
    :headers="headers"
    :items="listTable"
  >
    <template #default="{ item }">
      <argon-td
        :position="getPosition('concept.code')"
        :text-secondary="item.concept.code"
      ></argon-td>
      <argon-td
        :position="getPosition('description')"
        :text-secondary="item.description"
      ></argon-td>
      <argon-td
        :position="getPosition('quantity')"
        :text-secondary="item.quantity.toFixed(2)"
      ></argon-td>
      <argon-td
        :position="getPosition('unit_measurement')"
        :text-secondary="item.unit_measurement"
      ></argon-td>
      <argon-td
        :position="getPosition('customer_cost.unit_price')"
        :text-primary="`Cliente: ${proxy.$formatPrice(
          item.customer_cost,
          'unit_price',
          false
        )}`"
        :text-secondary="`Coste: ${proxy.$formatPrice(
          item.estimated_courier_cost,
          'unit_price',
          false
        )}`"
      ></argon-td>
      <argon-td
        :position="getPosition('customer_cost.amount')"
        :text-primary="`Cliente: ${proxy.$formatPrice(
          item.customer_cost.amount
        )}`"
        :text-secondary="`Coste: ${proxy.$formatPrice(
          item.estimated_courier_cost.amount
        )}`"
      ></argon-td>
    </template>
    <template #foot-table>
      <tr style="border-color: transparent !important;">
        <th colspan="5"></th>
        <td>
          <div
            class="row me-0"
            v-if="listTable && listTable.data && listTable.data.length > 0"
          >
            <div class="col-lg-12 text-start">
              <p class="text-xs pb-0 pt-0 col-form-label"
                >Total Cliente:
                {{ proxy.$formatPrice(valuation.total?.customer) }}</p
              >
            </div>
            <div class="col-lg-12 text-start">
              <p class="text-xs pb-0 pt-0 col-form-label"
                >Total Coste:
                {{ proxy.$formatPrice(valuation.total?.courier_cost) }}</p
              >
            </div>
          </div>
        </td>
      </tr>
    </template>
  </simple-table>
  <div v-else class="row my-3">
    <div class="col-lg-12 text-center">
      <span class="label-placeholder text-lg"
        >Envío sin valoración</span
      >
    </div>
  </div>
</template>