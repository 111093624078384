export const defaultFilterDates = [
  {
    label: "Hoy",
    value: "today",
  },
  {
    label: "Ayer",
    value: "yesterday",
  },
  {
    label: "Últimos 7 días",
    value: "last_7_days",
  },
  { label: "Últimos 30 días", 
    value: 'last_30_days' 
  },

];


