import createAxios from "./axiosHttp";


function getIssueNotificationAlert() {
  const axios = createAxios();
  let urlPath = `issue/alert`;
  return axios.get(urlPath);
}

export const notificationService = {
  getIssueNotificationAlert
};
