<!-- eslint-disable no-unused-vars -->
<script setup>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { dashboardStore } from "@/_store";
import { CounterCards, CouriersChart, ProvincesChart } from "./components";
import { logoListStore } from "@/_store/logoStore";
import { customerDetailStore } from "@/_store/customerDetailStore";

const route = useRoute();
const uploadStore = logoListStore();
const customerStoreRequest = customerDetailStore();
let clientData = null;

const store = dashboardStore();
const hasReloaded = sessionStorage.getItem('hasReloaded');

onMounted(() => {
  store.getDashboard()

  if (route.query.fromLogin && !hasReloaded) {

    sessionStorage.setItem('hasReloaded', 'true');

    setTimeout(() => {
      window.location.reload();
    }, 100);
  } else {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user.extraData.customerId)
      customerStoreRequest.getCustomerById(user.extraData.customerId);
    sessionStorage.removeItem('hasReloaded');
  }

});

watch(
  () => customerStoreRequest.customer,
  (value) => {
    if (value) {
      clientData = value;
      localStorage.setItem('cliente', JSON.stringify(clientData));
    }
  }
);
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <CounterCards />
        </div>
        <div class="row mb-3">
          <div class="col-lg-7 mb-lg">
            <CouriersChart />
          </div>
          <div class="col-lg-5">
            <ProvincesChart />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

