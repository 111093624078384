<script setup>
import { ref, onMounted, watch, inject } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import { pricingConceptsListStore } from "@/_store/pricingConceptsStore";
import { argonTableStore } from "@/_store/argonTableStore";
import ArgonTd from "@/components/table/ArgonTd.vue";
import moment from "moment";
import { userStore } from "@/_store/userStore";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ModalNewConcept from "./ModalNewConcept.vue";
import { getCurrentInstance } from "vue";
import ModalImportConceptFares from "./ModalImportConceptFares.vue";
const { proxy } = getCurrentInstance();
const pricingStore = pricingConceptsListStore();
const tableStore = argonTableStore();
const userStores = userStore();
const snackbar = ref(null);
let showImportConcepts = ref(false);
const sweetAlert = inject("$swal");
const showNewConcept = ref(false);
const actions = ref([]);
const typeNewConcept =ref("");
const actionEvents = ref({'importConcepts': importConcepts});
actions.value.push({label: 'Importar', event: 'importConcepts' })
const fieldFilters = ref([
  {
    field: "code",
    description: "Código",
  },
  {
    field: "description",
    description: "Nombre",
  },
  {
    field: "customer.code",
    description: "Cliente Código",
  },
  {
    field: "customer.contact.name",
    description: "Cliente Nombre",
  },
]);

const defaultFilters = [
  {
    field: "courier",
    label: "Courier",
    multi: true,
    options: [
      { label: "Correos Express", value: 'CEX'},
      { label: "Ctt Express", value: 'CTT' },
      { label: "Ups", value: 'UPS' },
      { label: "Seur", value: 'SEUR' },
    ],
  },
  {
    field: "is_custom",
    label: "Personalizado",
    multi: false,
    options: [
      { label: "Base", value: false },
      { label: "Personalizados", value: true }
       
    ],
  },
];

const columns = ref([  
  {
    header: "Código",
    field: "code",
    width: "w-10",
    position: "start",
  },
  {
    header: "Nombre",
    field: "description",
    width: "w-10",
    position: "start",
  },
  {
    header: "Cliente",
    field: "customer.name",
    width: "w-10",
    sort: false,
    position: "start"
  },
  {
    header: "Incio vigencia",
    field: "from_date",
    width: "w-10",
    position: "start",
    sort: true,
  },
  {
    header: "Fin vigencia",
    field: "to_date",
    width: "w-10",
    position: "start",
    sort: true,
  },  
  {
    header: "Valor",
    field: "prices",
    width: "w-10",
    position: "start",
  },
  {
    header: "Comisión mín.",
    field: "min_commission",
    width: "w-10",
    position: "start",
  },
  {
    header: "Comisión máx.",
    field: "max_commission",
    width: "w-10",
    position: "start",
  },  
  {
    header: "Partner",
    field: "courier",
    width: "w-10",
    position: "center",
  },
]);


onMounted(() => {
  tableStore.clear();
  const shipmentFilters = userStores.filtersPage.concepts?.filters || [];
  tableStore.changeFilters(shipmentFilters);
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.concepts = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  pricingStore.getPricingConcepts(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function importConcepts(){
  showImportConcepts.value = true;
}
function onEventAction(action){
  actionEvents.value[action]()
}
watch(
  () => pricingStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

function exportData() {
  pricingStore.getPricingConceptsAsExcel(tableStore.query, tableStore.sort);
}
const closeSnackbar = () => {
  snackbar.value = null;
};
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function deleteConcepts() {
  sweetAlert({
    title: "¿Está seguro de querer eliminar los suplementos seleccionados?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      pricingStore.deletePricingConcepts(tableStore.checkedList);
    }
  });
}
function newConcept(type) {
  typeNewConcept.value=type;
  showNewConcept.value = true;
}
function successModalImport(){
  snackbar.value = {
    title: "Enviado",
    message: "Fichero enviado para procesar",
    type: "info",
    color: "white",
  };
}
function successNewConcept(){
  reloadPage();
}
function closeModal() {
  showNewConcept.value = false;
};
function closeImportModal(){
  showImportConcepts.value=false;
}

watch(
  () => pricingStore.deleteConcepts,
  (value) => {
    if (value) {
      setTimeout(() => {       
        reloadPage();
      }, 1200);
      tableStore.clearSelection();
      snackbar.value = {
        title: "Actualizado",
        message: "Ha sido eliminado.",
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => pricingStore.pricingConceptsAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);
watch(
  () => pricingStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="pricingStore.pricingConcepts"
        :columns="columns"
        :items="pricingStore.pricingConcepts"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        @filter-changed="reloadPage"
        @on-reload="reloadPage"
        @sort-changed="reloadPage"
        :table-hover="false"
        @page-changed="reloadPage"
        @export-data="exportData"
        :show-importar="false"
        :show-new-button="false"
        @new-item="newConcept"
        :more-actions="actions"
        @event-action="onEventAction"
      >
        <template #button-custom>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="primary"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-plus me-sm-2"></i>Nuevo</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  @click="newConcept('SUP_CLIENTE')"
                  >Personalizar suplemento</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  @click="newConcept('PLUS_COMBUSTIBLE')"
                  >Plus de combustible</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="primary"
              variant="outline"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-exchange me-sm-2"></i>Acciones</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  @click="deleteConcepts"
                  >Eliminar</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">          
          
          <argon-td
            :position="getPosition('code')"
            :text-primary="item.code"
          />
          <argon-td
            :position="getPosition('description')"
            :text-secondary="item.description"
          />
          <argon-td
            :position="getPosition('customer.name')"
            :text-primary="item.customer?.code"
            :text-secondary="item.customer?.contact?.name"
          ></argon-td>
          <argon-td
            :position="getPosition('from_date')"
            :text-secondary="
              item.from_date != null
                ? moment(item.from_date).format('DD/MM/YYYY')
                : ''
            "
          ></argon-td>
          <argon-td
            :position="getPosition('to_date')"
            :text-secondary="
              item.to_date != null
                ? moment(item.to_date).format('DD/MM/YYYY')
                : ''
            "
          ></argon-td>

          <argon-td
            :position="getPosition('prices')"
            :text-primary="
              'Cliente: ' +
              proxy.$formatPrice((item.is_percentage?item.percentages:item.prices), 'customer', item.is_percentage)
            "
            :text-secondary="!item.is_custom ?
              'Coste: ' + proxy.$formatPrice((item.is_percentage?item.percentages:item.prices), 'cost', item.is_percentage) : 'NA'
            "
            
          />
          <argon-td
            :position="getPosition('min_commission')"
            :text-primary="
              'Cliente: ' + proxy.$formatPrice(item.min_commission, 'customer', false)
            "
            :text-secondary="!item.is_custom ?
              'Coste: ' + proxy.$formatPrice(item.min_commission, 'cost', false) : 'NA'
            "
          />
          <argon-td
            :position="getPosition('max_commission')"
            :text-primary="
              'Cliente: ' + proxy.$formatPrice(item.max_commission, 'customer', false)
            "
            :text-secondary="!item.is_custom ?
              'Coste: ' + proxy.$formatPrice(item.max_commission, 'cost', false) : 'NA'
            "
          />          
          <argon-td :position="getPosition('courier')">
            <partner-avatar :item="item.courier"></partner-avatar>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <modal-new-concept v-if="showNewConcept" :type="typeNewConcept" @close="closeModal" @success="successNewConcept" />
  <modal-import-concept-fares v-if="showImportConcepts" @close="closeImportModal" @success="successModalImport" />
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>