<script setup>
import { onMounted, watch, ref, computed } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import MarketplaceAvatar from "@/components/MarketplaceAvatar.vue";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ExpediteModal from "@/views/shipments/components/ModalExpedite.vue";
import {defaultFilterDates} from "@/maestros/defaultDatesValues";
import ModalWeightSorter from "./ModalWeightSorter.vue";

import { userStore } from "@/_store/userStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { shipmentListStore } from "@/_store";
import { useRouter } from "vue-router";
import { estados } from "@/maestros/shipmentStatus";
import moment from "moment";
const actions = ref([]);
const actionEvents = ref({
  onSorterWeight: onSorterWeight,
  onExpedite: onExpedite,
});
const tableStore = argonTableStore();
const shipmentStore = shipmentListStore();
const showSorterWeightModal = ref(false);
const showExpediteModal = ref(false);
const router = useRouter();
const snackbar = ref(null);
const fieldFilters = ref([
  {
    field: "locator",
    description: "Localizador",
  },
  {
    field: "reference",
    description: "Referencia",
  },
  {
    field: "delivery.name",
    description: "Destinatario",
  },
  {
    field: "service",
    description: "Servicio",
  },
  {
    field: "pickup.number",
    description: "Número Recogida",
  },
  {
    field: "delivery.number",
    description: "Número Entrega",
  },
]);

const userStores = userStore();
const customFilterDate= ref(shipmentStore.customFilterDate);

const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: estados,
  },
  {
    field: "courier",
    label: "Courier",
    multi: true,
    options: [
      { label: "Correos Express", value: "CEX" },
      { label: "Ctt Express", value: "CTT" },
      { label: "Ups", value: "UPS" },
      { label: "Seur", value: "SEUR" },
      { label: "Propio", value: "PROPIO" },
    ],
  },
  {
    field: "features.delivery_on_saturday",
    label: "Entrega Sábado",
    multi: true,
    options: [{ label: "Entrega Sábado", value: true }],
  },
  {
    field: "features.returns",
    label: "Retorno",
    multi: true,
    options: [{ label: "Retorno", value: true }],
  },
  {
    field: "pickup.date",
    label: "Fecha Recogida",
    multi: false,
    isDate: true,
    options: [ 
      ...defaultFilterDates,
      { label: "Personalizado", value: customFilterDate, isCustomDate: true, storage: shipmentStore, formatDate:  "DD/MM/YYYY"}
    ],
  },
];
const importOptions = {
  title: "Importar Envíos",
  options: TiposImportaciones.SHIPMENT.options,
  type: "SHIPMENT",
  url: `/shipment/batch`,
};
if (userStores.userSigned.portalType === "AGENCY") {
  defaultFilters.push({
    field: "priority",
    label: "Prioridad",
    multi: true,
    options: [
      { label: "Prioridad Alta", value: 3 },
      { label: "Prioridad Media", value: 2 },
      { label: "Prioridad Baja", value: 1 },
    ],
  });
}
if (userStores.userSigned.portalType != "AGENCY") {
  defaultFilters.push({
    field: "marketplace",
    label: "Marketplace",
    multi: true,
    options: [
      { label: "Shopify", value: "SHOPIFY" },
      { label: "Prestashop", value: "PRESTASHOP" },
      { label: "Woocommerce", value: "WOOCOMMERCE" },
    ],
  });
}


const columns = ref([
  {
    header: "Localizador",
    field: "locator",
    sort: false,
    position: "start",
  },
  {
    header: "Recogida",
    field: "pickup.date",
    position: "start",
    sort: true,
  },
  {
    header: "Destinatario",
    field: "delivery.name",
    position: "start",
    sort: false,
  },
  {
    header: "Servicio",
    field: "service",
    position: "center",
    sort: false,
  },
  {
    header: "Bultos",
    field: "packages",
    sort: false,
    position: "center",
  },
  {
    header: "Partner",
    field: "courier",
    sort: false,
    position: "center",
  },
  {
    header: "Estado",
    field: "status",
    sort: false,
    position: "center",
    width: "w-5",
  },
]);
onMounted(() => {
  if (isAgency.value) {
    loadAgencyConfigs();
  }
  if (!isAgency.value) {
    loadIsDifferenteAgency();
  }
  tableStore.clear();
  tableStore.clearPagination();
  const shipmentFilters = userStores.filtersPage.shipments?.filters || [];
  const shipmentFiltersFavorite =
    userStores.filtersPage.shipments?.filtersFavorite || [];

  tableStore.changeFilters(shipmentFilters);
  tableStore.addFavorite(shipmentFiltersFavorite);
  if (reload.value) {
    setTimeout(() => {
      reloadPage();
    }, 1600);
  }
  reloadPage();
  // key.value++
});
function loadIsDifferenteAgency() { 
  const newFilters = [    
    {
      field: "customer.department",
      description: "Departamento",
    },
  ];
  fieldFilters.value.push(...newFilters);
  const newHeader = {
    header: "Marketplace",
    field: "marketplace",
    sort: false,
    position: "center",
    width: "w-5",
  };
  columns.value.splice(6, 0, newHeader);
  actions.value.push({ label: "Escanear MAC", event: "onExpedite" });
}
function loadAgencyConfigs() {
  const newHeader = {
    header: "Cliente",
    field: "customer.name",
    width: "w-10",
    sort: false,
    position: "start",
  };
  const priorityHeader = {
    header: "Prioridad",
    field: "priority",
    width: "w-10",
    sort: true,
    position: "center",
  };
  columns.value.splice(3, 0, newHeader);
  columns.value.splice(2, 0, priorityHeader);
  const newFilters = [
    {
      field: "customer.code",
      description: "Cliente Código",
    },
    {
      field: "customer.name",
      description: "Cliente Nombre",
    },
    {
    field: "customer.trade_name",
    description: "Nombre comercial",
    },
    {
      field: "customer.department",
      description: "Departamento",
    },
  ];
  fieldFilters.value.push(...newFilters);
  actions.value.push({ label: "Pesos Sorter", event: "onSorterWeight" });
}
function reloadPage() {
  userStores.filtersPage.shipments = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  shipmentStore.getShipments(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function newShipment() {
  const url = `/shipments/new`;
  router.push(url);
}
function onClick(val) {
  router.push(`/shipments/${val._id}`);
}
function getShipmentsLabel() {
  shipmentStore.getShipmentLabel(tableStore.checkedList);
}
function deleteShipments() {
  shipmentStore.cancelMultipleShipments(tableStore.checkedList);
}
function exportData() {
  shipmentStore.getShipmentsAsExcel(tableStore.query, tableStore.sort);
}
function makeExport(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function getPriority(priority) {
  if (priority === 0) priority = 1;
  if (priority === undefined) {
    return "";
  }
  switch (priority) {
    case 3:
      return { icono: "fa fa-arrow-up text-xxs", tooltip: "Alta" };
    case 2:
      return { icono: "fa fa-arrow-right text-xxs", tooltip: "Media" };
    case 1:
      return { icono: "fa fa-arrow-down text-xxs", tooltip: "Baja" };
    default:
      return { icono: "", tooltip: "" };
  }
}
function onEventAction(action) {
  actionEvents.value[action]();
}
function closeModalSorterWeight() {
  showSorterWeightModal.value = false;
}
function successModalSorter() {
  snackbar.value = {
    title: "Enviado",
    message: "Fichero enviado para procesar",
    type: "info",
    color: "white",
  };
}
function onSorterWeight() {
  showSorterWeightModal.value = true;
}
function onExpedite() {
  showExpediteModal.value = true;
}
function closeExpedite() {
  showExpediteModal.value = false;
}
function convertArrayToList(array){
  return array.reduce((acc, item) => acc + `<li>${item}</li>`, '');
}
watch(
  () => shipmentStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => shipmentStore.canceledShipments,
  (value) => {
    if (value && value.canceled.length > 0) {
      snackbar.value = {
        title: "Éxito",
        message: "La eliminación de envíos se está procesando",
        type: "info",
        color: "white",
      };
      setTimeout(() => {
        tableStore.clearSelection();
        reloadPage()
      }, 2000);
    }
  }
);
watch(
  () => shipmentStore.shipmentsLabel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
watch(
  () => shipmentStore.shipmentAsExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
const closeSnackbar = () => {
  snackbar.value = null;
};

const reload = computed({
  get() {
    return shipmentStore.reload;
  },
});
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="shipmentStore.shipments"
        :columns="columns"
        :items="shipmentStore.shipments"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        :import-options="importOptions"
        :more-actions="actions"
        @filter-changed="reloadPage"
        @page-changed="reloadPage"
        @sort-changed="reloadPage"
        @on-reload="reloadPage"
        @new-item="newShipment"
        @on-click="onClick"
        @export-data="exportData"
        @event-action="onEventAction"
      >
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="primary"
              variant="outline"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-print me-sm-2"></i>Acciones</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="getShipmentsLabel"
                  >Etiqueta</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="deleteShipments"
                  >Anular Envío(s)</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td :position="getPosition('locator')">
            <p class="text-xs font-weight-bold mb-0">
              {{ item.locator }}
              <i v-if="item.warning && item.warning.length>0" v-tooltip :data-bs-title="convertArrayToList(item.warning)" class="fa fa-exclamation-triangle text-sm"></i>
            </p>
            <p class="text-xs text-secondary mb-0">
              {{ item.reference }}
            </p>
          </argon-td>
          <argon-td
            :position="getPosition('pickup.date')"
            :text-primary="moment(item.pickup.date).format('DD/MM/YYYY')"
            :text-secondary="item.pickup.number"
          ></argon-td>
          <argon-td
            v-if="isAgency"
            :position="getPosition('priority')"
            v-tooltip
            :data-bs-title="getPriority(item.priority).tooltip"
          >
            <i :class="getPriority(item.priority).icono"></i>
          </argon-td>
          <argon-td
            :position="getPosition('delivery.name')"
            :text-primary="item.delivery.name"
            :text-secondary="item.delivery.address"
            max-width="130px"
          ></argon-td>
          <argon-td
            v-if="isAgency"
            :position="getPosition('customer.name')"
            :text-primary="item.customer?.code"
            :text-secondary="item.customer?.name"
          ></argon-td>
          <argon-td
            :position="getPosition('service')"
            :text-secondary="item.service"
          ></argon-td>
          <argon-td
            :position="getPosition('packages')"
            :text-primary="item.packages"
            :text-secondary="item.weight.toFixed(2)"
          ></argon-td>
          <argon-td :position="getPosition('courier')">
            <partner-avatar :item="item.courier"></partner-avatar>
          </argon-td>
          <argon-td v-if="!isAgency" :position="getPosition('marketplace')">
            <marketplace-avatar :item="item.marketplace"></marketplace-avatar>
          </argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge
              v-if="item.errors.length > 0"
              class="badge badge-md"
              :item="item.status"
              :tooltip="item.errors"
            ></status-badge>
            <status-badge
              v-else
              class="badge badge-md"
              :item="item.status"
              :tooltip="item.status_description"
            ></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <modal-weight-sorter
    v-if="showSorterWeightModal"
    id="modalweightsorter"
    @close="closeModalSorterWeight"
    @success="successModalSorter"
  />
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
  <expedite-modal
    v-if="showExpediteModal"
    @close="closeExpedite"
  ></expedite-modal>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>