<script setup>
import TemplateFile from "./TemplateFile.vue";
import { ref, computed, watch } from "vue";
import { useUserStore } from "@/_store/userStore.js";
import { userStore as storeUser } from "@/_store/userStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const snackbar = ref(null);
const fileName = ref("");
const userStore = useUserStore();
const userStores = storeUser();
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
const plantillasList = computed(() => {
  const planillaOptions = [...optionsPlanilla.value];
  if (!isAgency.value) {
    planillaOptions.splice(2, 4);
  }
  return planillaOptions;
});

const optionsPlanilla = ref([
  { label: "Envíos", filename: "plantilla_envios" },
  { label: "Contactos", filename: "plantilla_contactos" },
  { label: "Clientes", filename: "plantilla_clientes" },
  {
    label: "Pesos sorter",
    filename: "plantilla_sorter",
    options: [
      { label: "Ctt Express", value: "xlsx", extra_name: "_ctt" },
      { label: "Correos Express", value: "xlsx", extra_name: "_cex" },
    ],
  },
  {
    label: "Tarifas y suplementos",
    filename: "plantilla_valoracion",
    options: [
      { label: "Suplementos", value: "xlsx", extra_name: "_conceptos" },
      {
        label: "Tarifas Precios",
        value: "xlsx",
        extra_name: "_precios_tarifas",
      },
    ],
  },
]);

function onDownloadFile(file) {
  fileName.value = file;
  userStore.onDownloadFile(fileName.value);
}

function download(blob, fileName) {
  const link = document.createElement("a");
  link.setAttribute("download", fileName);
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}

watch(
  () => userStore.templateFile,
  (value) => {
    if (value) {
      download(value, fileName.value);
    }
  }
);

watch(
  () => userStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card h-100">
    <div class="card-header">Plantillas ficheros masivos</div>
    <div class="card-body">
      <template-file
        v-for="item in plantillasList"
        :key="item.filename"
        :label="item.label"
        :file-name="item.filename"
        :options="item.options"
        @on-download="onDownloadFile"
      />
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>