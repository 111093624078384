<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import { ref, watch, onMounted } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import { dateUtils } from "@/utils";

const localValue = ref({
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  schedules: {
    initial: "",
    final: "",
  },
  instructions: "",
});
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  },
  courier: {
    type: String,
    default: "",
  },
});

const checkHoraMayor = () => {
  let inicioInt = horaToInt(localValue.value.schedules.initial);
  let finalInt = horaToInt(localValue.value.schedules.final);
  if (inicioInt == 0 || finalInt == 0) return true;
  if (inicioInt >= finalInt) return false;
  return true;
};
function horaToInt(value) {
  if (value == "") return 0;
  if (!value.includes(":")) return 0;
  let hora = parseInt(value.split(":")[0]) * 3600;
  let minutos = parseInt(value.split(":")[1]) * 60;
  return hora + minutos;
}

const rules = {
  date: {
    required: helpers.withMessage("Por favor ingrese una fecha.", required),
  },
  schedules: {
    initial: {
      required: helpers.withMessage("Por favor ingrese el horario de inicio.",  requiredIf(props.courier=='CEX')),
    },
    final: {
      required: helpers.withMessage("Por favor ingrese el horario de fin.",  requiredIf(props.courier=='CEX')),
      checkHoraMayor: helpers.withMessage("La hora fin debe de ser mayor a la inicial.", checkHoraMayor),
    },
  },
};

const v$ = useVuelidate(rules, localValue);

onMounted(() => {
  localValue.value = props.modelValue
});

watch(
  () => localValue.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true }
);

async function validateDateChange() {
  v$.value.$touch();
  await v$.value.$validate();
  return v$.value.$errors;
}

function resetModel() {
  localValue.value = {
    date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
    schedules: {
      initial: "",
      final: "",
    },
    instructions: "",
  };
  v$.value.$reset();
}

defineExpose({
  validateDateChange,
  resetModel,
});
</script>

<template>
  <div>
    <div>
      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-6">
              <label class="col-form-label required">Fecha</label>
            </div>
            <div class="col-6">
              <argon-input id="date" :error="v$.date.$error" v-model="localValue.date" type="date" />
            </div>
          </div>
        </div>
        <div v-if="courier=='CEX'" class="col-4">
          <div class="row">
            <div class="col-6">
              <label class="col-form-label required">Horario inicio</label>
            </div>
            <div class="col-6">
              <argon-input v-model="localValue.schedules.initial" type="time" :error="v$.schedules.initial.$error" />
            </div>
          </div>
        </div>
        <div v-if="courier=='CEX'" class="col-4">
          <div class="row">
            <div class="col-6">
              <label class="col-form-label required">Horario fin</label>
            </div>
            <div class="col-6">
              <argon-input v-model="localValue.schedules.final" type="time" :error="v$.schedules.final.$error" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-2">
          <label class="col-form-label">Observaciones</label>
        </div>
        <div class="col-10">
          <argon-input v-model="localValue.instructions" type="text" />
        </div>
      </div>
    </div>
  </div>
</template>
