<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import { ref, onMounted } from "vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { required, integer, helpers, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

let model = ref({
  packages: 1,
  weight: 1,
  height: 10,
  length: 10,
  width: 10,
  reference: "",
});

const rules = {
  packages: {
    required: helpers.withMessage("Número de bultos es requerido", required),
    integer: helpers.withMessage("Número de bultos debe ser entero", integer),
    minValue: helpers.withMessage(
      "Número de bultos debe ser mayor a 0",
      minValue(1)
    ),
  },
  weight: {
    required: helpers.withMessage("Peso es requerido", required),
    minValue: helpers.withMessage(
      "Kg debe mayor a 0",
      minValue(0.01)
    ),
  },
  height: {
    required: helpers.withMessage("Alto es requerido", required),
    minValue: helpers.withMessage(
      "Alto debe ser mayor a 0",
      minValue(0.01)
    ),
  },
  length: {
    required: helpers.withMessage("Largo es requerido", required),
    minValue: helpers.withMessage(
      "Largo debe ser mayor a 0",
      minValue(0.01)
    ),
  },
  width: {
    required: helpers.withMessage("Ancho es requerido", required),
    minValue: helpers.withMessage(
      "Ancho debe ser mayor a 0",
      minValue(0.01)
    ),
  },
};

const v$ = ref(useVuelidate(rules, model));
let props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  inputsSize: {
    type: String,
    default: "default",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const headers = [
  {
    header: "Kilos",
    field: "weight",
  },
  {
    header: "Largo(cm)",
    field: "length",
  },
  {
    header: "Ancho(cm)",
    field: "width",
  },
  {
    header: "Alto(cm)",
    field: "height",
  },
  {
    header: "Referencia",
    field: "reference",
  },
  {
    header: "Localizador",
    field: "locator",
  },
  {
    header: "",
    width: "w-0",
    position:"end"
  },
];

let rows = ref({ data: [] });

onMounted(() => {
  rows.value.data = props.modelValue;
});

function agregarBultos() {
  const promiseBultos = validateBultos();
  promiseBultos.then((values) => {
    if(!props.readonly){
      let message = "";
      for (const value of values) {
        message = `${message}<li>${value.$message}</li>`;
      }
      if (message != "") {
        snackbar.value = {
          title: "Error",
          message: `<ul>${message}</ul>`,
          type: "danger",
          color: "white",
        };
      } else {
        let packages = parseInt(model.value.packages);
        const weight = model.value.weight / packages;
        for (let i = 0; i < packages; i++) {
          rows.value.data.push({
            weight: Number(weight.toFixed(2)),
            height: model.value.height,
            length: model.value.length,
            width: model.value.width,
            reference: model.value.reference,
          });
        }
      }
    }
  });
}

async function validateBultos() {
  await v$.value.$validate();
  return v$.value.$errors;
}

function eliminarBulto(item) {
  const index = rows.value.data.indexOf(item);
  if (index > -1) rows.value.data.splice(index, 1);
}
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

</script>

<template>
  <div class="p-1">
    <div class="row" v-if="!readonly">
      <div class="col">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">Bultos</label>
          </div>
          <div class="col-9">
            <argon-input
              id="packages"
              v-model="model.packages"
              :error="v$.packages.$error"
              class="ms-2"
              :is-required="true"
              type="number"
              placeholder=""
              :disabled="readonly"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-6">
            <label class="col-form-label">Total Kg.</label>
          </div>
          <div class="col-6">
            <argon-input
              id="width"
              v-model.number="model.weight"
              :error="v$.width.$error"
              class="ms-2"
              :is-required="true"
              type="number"
              placeholder=""
              :disabled="readonly"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label">Largo(cm)</label>
          </div>
          <div class="col-8">
            <argon-input
              id="length"
              v-model.number="model.length"
              :error="v$.length.$error"
              class="ms-2"
              :is-required="true"
              type="number"
              placeholder=""
              :disabled="readonly"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label">Ancho(cm)</label>
          </div>
          <div class="col-8">
            <argon-input
              id="width"
              v-model.number="model.width"
              :error="v$.width.$error"
              class="ms-2"
              :is-required="true"
              type="number"
              placeholder=""
              :disabled="readonly"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label">Alto(cm)</label>
          </div>
          <div class="col-8">
            <argon-input
              id="height"
              v-model.number="model.height"
              :error="v$.height.$error"
              class="ms-2"
              :is-required="true"
              type="number"
              placeholder=""
              :disabled="readonly"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">Ref.</label>
          </div>
          <div class="col-9">
            <argon-input
              id="reference"
              v-model="model.reference"
              :max-length="30"
              class="ms-2"
              type="text"
              placeholder=""
              :disabled="readonly"
            />
          </div>
        </div>
      </div>
      <div class="col-1">
        <a href="#" class="link-primary" @click="agregarBultos">Añadir</a>
      </div>
    </div>
    <simple-table :headers="headers" :items="rows">
      <template #default="{ item }">
        <argon-td  v-if="readonly" :text-secondary="item.weight">
        </argon-td>
        <td  v-else>
          <argon-input
            v-model.number="item.weight"
            type="number"
            placeholder=""
            class="mb-0"
            :disabled="readonly"
          />
        </td>
        <argon-td  v-if="readonly" :text-secondary="item.length">
        </argon-td>
        <td  v-else>
          <argon-input
            v-model.number="item.length"
            type="number"
            placeholder=""
            class="mb-0"
            :disabled="readonly"
          />
        </td>
        <argon-td  v-if="readonly" :text-secondary="item.width">
        </argon-td>
        <td  v-else>
          <argon-input
            v-model.number="item.width"
            type="number"
            placeholder=""
            class="mb-0"
            :disabled="readonly"
          />
        </td>
        <argon-td  v-if="readonly" :text-secondary="item.height">
        </argon-td>
        <td  v-else>
          <argon-input
            v-model.number="item.height"
            type="number"
            placeholder=""
            class="mb-0"
            :disabled="readonly"
          />
        </td>
        <argon-td  v-if="readonly" :text-secondary="item.reference">
        </argon-td>
        <td  v-else>
          <argon-input
            v-model="item.reference"
            :max-length="30"
            type="text"
            placeholder=""
            class="mb-0"
            :disabled="readonly"
          />
        </td>
        <argon-td v-if="item.locator" :text-secondary="item.locator">
        </argon-td>
        <td class= "text-end w-5">
          <a
            v-if="!readonly"
            href="javascript:;"
            v-tooltip
            data-bs-title="Eliminar"
            @click="eliminarBulto(item)"
          >
            <i class="fas fa-trash text-xs mb-4"></i>
          </a>
        </td>
      </template>
    </simple-table>
    <div class="position-fixed top-1 end-1 z-index-2">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>