import { defineStore } from 'pinia';
import { issueService } from '@/_services';


export const issueListStore = defineStore('issueList', {
  state: () => ({
    issues: null,
    error: null,
    issuesAsExcel: null, 
    reload: false,
    modifyStatus:null,
    customFilterDate: null,
  }),
  actions: {
    setCustomFilterDate(value){
      this.customFilterDate= value;
    },
    getAllIssues(pagination, filters, sort) {
      this.reload = false
      issueService.getAllIssues(pagination, filters, sort)
        .then((results) => this.issues = results)
        .catch((reason) => this.error = reason);
    },
    getIssuesAsExcel(query, sort) {
      issueService.getIssuesAsExcel(query, sort)
          .then((results) => this.issuesAsExcel = results)
          .catch((reason) => this.error = reason);
    },
    putIssuesStatus(body) {
      issueService.putIssuesStatus(body)
          .then((results) => this.modifyStatus = results)
          .catch((reason) => this.error = reason);
    }
  },
})
