<script setup>
import { ref, onMounted,watch } from "vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";

let model = ref("");

let props = defineProps({
  modelValue: {
    type: String,
    default: "",

  },
});

onMounted(() => {
  model.value = props.modelValue;
});
let emit = defineEmits(["update:modelValue"]);

watch(
  () => model.value,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);
</script>
<template>
  <div role="form" class="p-1">
      <div class="row">
        <div class="col-6">
          <div class="row">            
            <div class="col-12">
              <argon-textarea
                v-model="model"
                :rows="2"
                :max-length="180"
                type="textarea"
                :resize="false"
              />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>