<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import DropZone from "@/components/dropzone/DropZone.vue";
import { ArgonLoadingButton } from "@/components";
import FileView from "@/components/dropzone/FileView.vue";
import { dropzoneStore } from "@/components/dropzone/dropzoneStore";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import { onMounted, watch, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
const store = useStore();
const bootstrap = store.state.bootstrap;
const modalweightsorter = ref(null);
const dropStore = dropzoneStore();
const emits = defineEmits(["close", "success"]);

function onInputChange(e) {
  dropStore.addFiles(e.target.files);
  e.target.value = null;
}
const options = [{ value: "CEX", label: "Correos Express" },
{ value: "CTT", label: "Ctt Express" },
{ value: "UPS", label: "Ups" },
{ value: "SEUR", label: 'Seur' },
]
const rules = {
  courier: { required: helpers.withMessage("Courier es requerido", required) },
};

const data = ref({
  courier: "",
});
const v$ = ref(useVuelidate(rules, data));
onMounted(() => {
  let statusModal = new bootstrap.Modal(
    document.getElementById("modalweightsorter"),
    {
      keyboard: true,
    }
  );
  statusModal.show();
  dropStore.clearFiles();
});
function resetModalState() {
  dropStore.clearFiles();
  let formData = document.getElementById("formData");
  const invalidElements = formData?.querySelectorAll(".is-invalid");
  invalidElements.forEach((element) => {
    element.classList.remove("is-invalid");
  });
  emits("close");
}
function validateFile() {
  return dropStore.files.length > 0;
}

function removeFile(file) {
  dropStore.removeFile(file);
}
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
async function upload() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message = "";
    if (!validateFile()) {
      message = `${message}<li>Debe seleccionar al menos un fichero</li>`;
    }
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      if (!validateFile()) {
        let dropZonesorter = document.getElementById("dropZonesorter");
        if (dropZonesorter) {
          if (!dropZonesorter.classList.contains("is-invalid")) {
            dropZonesorter.classList.add("is-invalid");
          }
        }
      }
      snackbar.value = {
        title: "Error",
        message: message,
        type: "danger",
        color: "white",
      };
      return;
    }
    const contract = { courier: data.value.courier };
    dropStore.uploadFiles(`/shipment/sorter/batch`, contract);
  });
}

function onClick() {
  let dropZonesorter = document.getElementById("dropZonesorter");
  if (dropZonesorter) {
    if (dropZonesorter.classList.contains("is-invalid")) {
      dropZonesorter.classList.remove("is-invalid");
    }
  }
}
watch(
  () => dropStore.uploadStatus,
  (newSelection) => {
    if (newSelection == "SUCCESS") {
      emits("success");
      modalweightsorter.value.closeModal();
    }
  }
);
watch(
  () => dropStore.error,
  () => {
    snackbar.value = {
      title: "Error",
      message: dropStore.error.message,
      type: "danger",
      color: "white",
    };
  }
);
const disableButton = computed({
  get() {
    return dropStore.disableButton;
  },
});
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <modal-base ref="modalweightsorter" @hide="resetModalState">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">Pesos sorter</h6>
    </template>
    <template #body>
      <form id="formData" class="form-control border-0">
        <DropZone id="dropZonesorter" v-slot="{ dropZoneActive }" class="drop-area radius drop-zone-area p-0"
          @click="onClick" @files-dropped="dropStore.addFiles">
          <label for="file-input" class="file-input-label">
            <span v-if="dropZoneActive" class="file-input-text">
              <i class="fa fa-upload"></i>
              <span>Soltar sus archivos aquí</span>
              <span class="smaller">para agregarlo</span>
            </span>
            <span v-else class="file-input-text">
              <span class="col-form-label">Arrastre sus archivos o pulsa aquí para seleccionarlos</span>
            </span>
          </label>
          <input id="file-input" type="file" multiple @change="onInputChange" />
        </DropZone>
        <file-view :files="dropStore.files" @remove="removeFile"></file-view>
        <div v-if="options.length > 0" class="mt-2">
          <argon-select id="courier-type" v-model="data.courier" name="courier-type" :options="options" filterable
            :is-required="true" placeholder="Courier" :error="v$.courier.$error">
          </argon-select>
        </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button class="btn bg-gradient-primary btn-sm" color="primary" variant="gradient"
        :label="disableButton ? 'Importando...' : 'Importar'" :loading="disableButton" :disable-button="disableButton"
        @click="upload">
      </argon-loading-button>
    </template>
    <div class="position-fixed top-0 end-0 z-index-2 p-3">
      <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
        :close-handler="closeSnackbar" />
    </div>
  </modal-base>
</template>