import { defineStore } from 'pinia';
import { notificationService } from '@/_services';
import { userStore } from "@/_store/userStore";


export const notificationListStore = defineStore('notificationList', {
  state: () => ({
    error: null,
    issueNotification: null,
    intervalIssueId:null
  }),
  actions: {
    getNotificationIssueAlert(){
      notificationService.getIssueNotificationAlert()
      .then((results) => this.issueNotification = results)
      .catch((reason) => this.error = reason);
    },
    setNotificationIssueAlert(value){
      this.issueNotification= value;
    },
    startIssueAlertInterval() {
      const useUser = userStore();
      if (!useUser.isAuthenticated) return;
      if (this.intervalIssueId)  return;
      this.getNotificationIssueAlert();
      this.intervalIssueId = setInterval(() => {
        this.getNotificationIssueAlert();
      }, 60000);
    },
    stopIntervals() {
      if (this.intervalIssueId) {
        clearInterval(this.intervalIssueId); 
        this.intervalIssueId = null;
      }
    },
  },
})
