<script setup>

defineProps({
  item: {
    type: String,
    default: "",
  },
  zoomeable: {
    type: Boolean,
    default: false,
  },
});
import {computed} from "vue";

import ArgonAvatar from "@/components/ArgonAvatar.vue";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import ups from "@/assets/img/logos/partners/ups.svg";
import seur from "@/assets/img/logos/partners/seur.png";
import propio from "@/assets/img/logos/newLogoAgency.png";
import { logoListStore } from "@/_store/logoStore";

const uploadStore = logoListStore();

const customLogo= computed(()=> 
{
    if ( uploadStore.logo && uploadStore.logo.icon!= '' ) {
      return uploadStore.logo.icon
    }
    else {
      return null
    }
  })
const partners = {
  CEX: cex,
  CTT: ctt,
  UPS: ups,
  SEUR: seur,
  PROPIO: customLogo.value ? customLogo.value : propio
};
</script>
<template>
  <argon-avatar 
  v-if="item" 
  v-tooltip
  :data-bs-title="item != '' ? item : null" 
  :image="partners[item]" size="xs" :zoomeable="zoomeable" shadow="xs" alt="Avatar" :circular="true" style="padding-top: 3px; padding-bottom: 3px" />
</template>

<style scoped>
.size {
  min-width: 20px;
  height: 28px;
}
</style>