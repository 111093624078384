import moment from "moment";

function getToday() {
  return moment.utc();
}

function getRangeOfToday() {
  const today = getToday();
  return {
    start: today.clone().startOf("day"),
    end: today.clone().endOf("day"),
  };
}
function getRangeOfYesterday() {
  const today = getToday();
  return {
    start: today.clone().subtract(1, 'days').startOf("day"),
    end: today.clone().subtract(1, 'days').endOf("day"),
  };
}
function getRangeThisWeek() {
  const today = getToday();
  return {
    start: today.clone().startOf("week"),
    end: today.clone().endOf("week"),
  };
}
function getRangeLast7days() {
  const today = getToday();
  return {
    start: today.clone().subtract(6, 'days').startOf("day"),
    end: today.clone().endOf("day"),
  };
}
function getRangeLast30days() {
  const today = getToday();
  return {
    start: today.clone().subtract(29, 'days').startOf("day"),
    end: today.clone().endOf("day"),
  };
}
function getRangeThisMonth() {
  const today = getToday();
  return {
    start: today.clone().startOf("month"),
    end: today.clone().endOf("month"),
  };
}

function getRangeThisQuarter() {
  const today = getToday();
  return {
    startOfQuarter: today.clone().startOf("quarter"),
    endOfQuarter: today.clone().endOf("quarter"),
  };
}

function formatDate(fecha, format = "DD/MM/YYYY HH:mm") {
  return moment(fecha).format(format);
}

function add(date, unit, duration) {
  return moment(date).add(duration, unit);
}

export const dateUtils = {
  getToday,
  getRangeOfYesterday,
  getRangeOfToday,
  getRangeLast7days,
  getRangeThisWeek,
  getRangeThisMonth,
  getRangeThisQuarter,
  getRangeLast30days,
  formatDate,
  add,
};
