<script setup>
import { onMounted, computed, watch, ref } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Direction from "@/components/forms/Direction.vue";
import Contact from "@/components/forms/Contact.vue";
import Horario from "@/components/forms/Horario.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import AutoComplete from "@/components/auto-complete/AutoComplete.vue";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import seur from "@/assets/img/logos/partners/seur.png";
import ups from "@/assets/img/logos/partners/ups.svg";
import propio from "@/assets/img/logos/logo.svg";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import { shipmentListStore } from "@/_store/shipmentStore";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf, helpers } from "@vuelidate/validators";
import { userStore } from "@/_store/userStore";
const userStores = userStore();
const iconAutoComplete= ref("");
const shipmentStore = shipmentListStore();
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {
        contact: {
          name: "",
          vat_number: "",
          email: "",
          attention_of: "",
          phone: "",
        },
        address: {
          postal_code: "",
          street: "",
          street2: "",
          city: "",
          country: "ES",
          location: {
            lat: 0,
            lng: 0,
          },
          text: "",
          place_id: "",
          normalized: true,
          province: "",
        },
        schedules: {
          first_frequency: {
            initial: "",
            final: "",
          },
          second_frequency: {
            initial: "",
            final: "",
          },
        },
        error: "",
        type: "REMITENTE",
        default: false,
      };
    },
  },
  customerId: { type: String,
    default: "",},
  name: {
    type: String,
    default: "",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isPickup: {
    type: Boolean,
    default: false,
  }
});
const model = ref(null);
const keyUpdate = ref(0);
let point = ref("");
let contactForm = ref(null);
let horarioForm = ref(null);
let directionForm = ref(null);
const dataSuggestions = ref([]);

const is_in_point = computed(() =>
  model.value != null ? model.value.in_point : false
);
let rules = {
  point_code: {
    requiredIfFoo: helpers.withMessage(
      "Punto es requerido",
      requiredIf(is_in_point)
    ),
  },
};
const v$ = useVuelidate(rules, model);

onMounted(() => {
  model.value = props.modelValue;
  if (model.value.in_point) {
    point.value = model.value.point_name
    ? model.value.point_name
    : model.value.point_code;
  }
});
function selectedContact(value) {
  model.value = value;
  model.value.in_point = false;
  model.value.point_code=""
  keyUpdate.value += 1;
}
let pagination=false;
function getPoints(text,page) {
  if(page==1){
    pagination=true;
  }else pagination =false;
  let req = text!=""?{ $or: [{ name: { $regex: text, $options: "i" } }, { 'address.postal_code': { $regex: text, $options: "i" } }]}:{};
  shipmentStore.getPoints(
    { page: page, limit: 80 },
    req,
    { courier: 1, 'address.postal_code': 1 }
  );
}

function getImage(item){
  if (item.courier==='CEX') return cex;
  if(item.courier==='CTT') return ctt;
  if(item.courier==='UPS') return ups;
  if(item.courier==='SEUR') return seur;
  return beeLogo.value;
}

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
defineExpose({
  validate,
});
function selectedAutocomplete(value) {
  point.value = value.name;
  model.value.point_code = value.code;
  model.value.address = value.address;
  iconAutoComplete.value=getImage(value);
}
const labelDir = computed(() => (model.value.in_point ? "Punto" : "Dirección"));


const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
const beeLogo= computed(() => isAgency.value ? propio_agency : propio);
watch(
  () => model.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true }
);
watch(
  () => shipmentStore.points,
  (value) => {
    if(value && value.data.length > 0){
      if(pagination)
        dataSuggestions.value =[];
      dataSuggestions.value = [...dataSuggestions.value, ...value.data];
    }
  },
);
</script>
<template>
  <form v-if="model" role="form">
    <contact
      :key="keyUpdate"
      ref="contactForm"
      v-model="model.contact"
      :type="name"
      :readonly="readonly"
      :customer-id="props.customerId"
      @selected-contact="selectedContact"
    />
    <div class="row">
      <div class="col-3">
        <label class="col-form-label required">{{ labelDir }} 
        </label>
        <argon-switch
          v-if="!readonly && !isPickup"
          id="default"
          :key="keyUpdate"
          v-model="model.in_point"
          name="default"
          :disabled="readonly"
        >
        <sup v-if="!model.in_point">En punto</sup>
      </argon-switch>
      </div> 
      <div class="col-9">
        <auto-complete
          v-if="model.in_point"
          v-model="point"
          id="idPoint"
          :icon-selected="iconAutoComplete"
          placeholder="Punto de red"
          :data-suggestions="dataSuggestions"
          :item-text="['address.postal_code','name']"
          separator=" - "
          size="default"
          :disabled="readonly"
          :error="v$.point_code.$error"
          @text-changed="getPoints"
          @page-changed="getPoints"
          @selected-change="selectedAutocomplete"
          menu-width="500"
        >
          <template #icon="{item}">
            <img :src="getImage(item)" style="margin-right:5px; width: 16px; height: 16px; border-radius: 50%; object-fit: cover;" />
          </template>
        </auto-complete>
        <direction
          v-else
          :key="keyUpdate"
          ref="directionForm"
          v-model="model.address"
          :readonly="readonly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label class="col-form-label">Horario</label>
      </div>
      <div class="col-9">
        <horario
          ref="horarioForm"
          :key="keyUpdate"
          v-model="model.schedules"
          :readonly="readonly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label class="col-form-label">Observaciones</label>
      </div>
      <div class="col-9">
        <argon-input
          v-model="model.observations"
          :is-required="true"
          placeholder=""
          name="name"
          :disabled="readonly"
        />
      </div>
    </div>
    <table class="w-100">
      <slot name="tr-bottom"></slot>
    </table>
  </form>
</template>
<style lang="scss" scoped>
.table-label {
  vertical-align: top;
}
.width-min {
  min-width: 73px;
}
</style>

