<script setup>
import { onMounted, watch, computed, ref } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";

import { userStore } from "@/_store/userStore";
import { customerListStore } from "@/_store/customerStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import ArgonTd from "@/components/table/ArgonTd.vue";
const fieldFilters = ref([
  {
    field: "code",
    description: "Código",
  },
  {
    field: "contact.name",
    description: "Nombre",
  },
  {
    field: "contact.trade_name",
    description: "Nombre comercial",
  },
  {
    field: "contact.attention_of",
    description: "Atención de",
  },
  {
    field: "contact.vat_number",
    description: "Nif",
  },
]);

const importOptions = {
  title: "Importar Clientes",
  options: TiposImportaciones.CUSTOMER.options,
  url: `/customer/batch`,
};

const defaultFilters = [
{
    field: "active",
    label: "Activado",
    multi: false,
    options: [
      { label: "Activado", value: true },
      { label: "Desactivado", value: false }, 
      
    ],
  },
  {
    field: "priority",
    label: "Prioridad",
    multi: true,
    options: [
      { label: "Prioridad Alta", value: 3 },
      { label: "Prioridad Media", value: 2 },
      { label: "Prioridad Baja", value: 1 },
    ],
  }
];

const customerStore = customerListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();

const columns = ref([
  {
    header: "Cliente",
    field: "contact.name",
    sort: false,
    position: "start",
    width: "w-25",

  },
  {
    header: "Nif",
    field: "contact.vat_number",
    sort: false,
    position: "start",
    width: "w-10"
  },
  {
    header: "Prioridad",
    field: "priority", 
    sort: true,
    position: "center",
  },
  {
    header: "Activo",
    field: "active",
    sort: false, 
    position: "center",
  },
]);

onMounted(() => {
  tableStore.clear();
  const filters = userStores.filtersPage.customers?.filters || [];
  const filtersFavorite = userStores.filtersPage.customers?.filtersFavorite || [];
  tableStore.changeFilters(filters);
  tableStore.addFavorite(filtersFavorite);

  if (reload.value) {
    setTimeout(() => {
      reloadPage()
    }, 2000)
  }
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.customers = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  }
  customerStore.getAllCustomers(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function newContact() {
  const url = `/customers/new`;
  router.push(url);
}

function exportData() {
  customerStore.getCustomersAsExcel(tableStore.query, tableStore.sort);
}
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
watch(
  () => customerStore.customersAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);

function onClick(val) {
  const url = `/customers/${val._id}`;
  router.push(url);
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function getPriority(priority) {
  if(priority===0) priority = 1;
  switch (priority) {
    case 3:
      return {icono:'fa fa-arrow-up text-xxs', tooltip:"Alta"};
    case 2:
      return {icono:'fa fa-arrow-right text-xxs', tooltip:"Media"};
    case 1:
      return {icono:'fa fa-arrow-down text-xxs', tooltip:"Baja"};
    default:
      return {icono:'', tooltip:""};
  }
}

const reload = computed({
  get() {
    return customerStore.reload
  },
})

</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table v-if="customerStore.customers" :columns="columns" :items="customerStore.customers"
        :field-filters="fieldFilters" :import-options="importOptions" @filter-changed="reloadPage"
        @page-changed="reloadPage" @new-item="newContact" @on-reload="reloadPage" @on-click="onClick"
        @sort-changed="reloadPage" @export-data="exportData" :default-filters="defaultFilters" :show-check-input="false">
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button id="navbarDropdownPrinter"
              color="primary" variant="outline"
              class="dropdown-toggle me-2 btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="fa fa-print me-sm-2"></i>Imprimir</argon-button>
            <ul class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter" style>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">Etiquetas</a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">Manifiesto</a>
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td :position="getPosition('contact.name')" :text-primary="item.contact.name"
            :text-secondary="item.code"></argon-td>
          <argon-td :position="getPosition('contact.vat_number')" :text-secondary="item.contact.vat_number" />
          <argon-td v-tooltip :data-bs-title="getPriority(item.priority).tooltip" :position="getPosition('priority')">
            <i :class="getPriority(item.priority).icono"></i>
          </argon-td>
          <argon-td :position="getPosition('active')">
            <i v-if="item.active" class="fa fa-check text-xxs"></i>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>