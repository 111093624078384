<script setup>
import { ref, onMounted,watch} from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { email, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

let model = ref("");

let props = defineProps({
  modelValue: {
    type: String,
    default: "",
    
  },
});

onMounted(() => {
  model.value = props.modelValue;
});
let emit = defineEmits(["update:modelValue"]);
const rules = {
  email: helpers.withMessage("Ingrese un email de incidencia válido", email)
}
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
defineExpose({
  validate,
});
const v$ = ref(useVuelidate(rules, model));
watch(
  () => model.value,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);
</script>
<template>
  <div role="form" class="p-1">
      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-3">
              <label class="col-form-label">Incidencias</label>
            </div>
            <div class="col-9">
              <argon-input  id="issue_email" v-model="model" :max-length="75" type="email" :error="v$.$error"/>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>