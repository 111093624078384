import { defineStore } from "pinia";
import { objectUtils } from "@/utils";
import { filterService } from "@/_services";
import { dateUtils } from "@/utils";

export const argonTableStore = defineStore("argonTable", {
  state: () => ({
    pagination: {
      page: 1,
      limit: 80,
    },
    sort: {
      _id: -1,
    },
    checkedList: [],
    selectedPage: false,
    selectedAllItems: false,
    selectedAllTotal: 0,
    filters: [],
    filtersCustom: [],
    query: {},
    filterByName:null
  }),
  getters: {
    hasSelectedItems(state) {
      return state.checkedList.length > 0;
    },
    selectedCount(state) {
      return state.selectedAllItems
        ? state.selectedAllTotal
        : state.checkedList.length;
    },
  },
  actions: {
    clearPagination() {
      this.pagination = {
        page: 1,
        limit: 80,
      };
    },
    clearSort() {
      this.sort= {
        _id: -1,
      }
    },
    clearSelection() {
      this.selectedPage = false;
      this.selectedAllItems = false;
      this.selectedAllTotal = 0;
      this.checkedList = [];
    },
    clear() {
      this.clearSelection();
      this.clearPagination();
      this.clearSort()
    },
    selectedAll(count) {
      this.selectedAllTotal = count;
      this.selectedAllItems = true;
    },
    addSelectedRow(rowId) {
      this.checkedList.push(rowId);
    },
    removeSelectedRow(rowId) {
      this.selectedAllItems = false;
      this.selectedAllTotal = 0;
      const index = this.checkedList.indexOf(rowId);
      this.checkedList.splice(index, 1);
    },
    toogleAllPage() {
      this.selectedPage = !this.selectedPage;
    },
    changeFilters(newFilters) {
      this.filters = objectUtils.copyByValue(newFilters);
      this.query = this.makeQuery();
    },
    addFavorite(favorite, route) {
      if (favorite == "") return;
      if (!this.filters.length > 0) return {};
      const filterFav = {
        name: favorite,
        filters: this.filters,
        route,
      };
      filterService
        .createFilter(filterFav)
        .then((results) => (this.filtersCustom = objectUtils.copyByValue(results.data)))
        .catch((reason) => (this.error = reason));
    },
    deleteFavorite(idFilter) {
      filterService
        .deleteFilter(idFilter)
        .then((results) => (this.filtersCustom = objectUtils.copyByValue(results.data)))
        .catch((reason) => (this.error = reason));
    },
    getFilters(filters) {
      filterService
        .getFilters(filters)
        .then((results) => (this.filtersCustom = objectUtils.copyByValue(results.data)))
        .catch((reason) => (this.error = reason));
    },
    getByNameFilter(query){
      filterService
        .getFilters(query)
        .then((results) => (this.filterByName = results.data))
        .catch((reason) => (this.error = reason));
    },
    makeQuery() {
      if (!this.filters.length > 0) return {};
      const defaultFilters = this.filtersByType("default");
      const textFilters = this.groupFilters(this.filtersByType("text"));

      const queryDefault = this.makeQueryDefault(defaultFilters);
      const queryText = this.makeQueryText(textFilters);
      return { ...queryText, ...queryDefault };
    },
    makeQueryDefault(filters) {
      if (filters.length == 0) return {};

      return Object.fromEntries(
        filters.map((filter) => {
          if(!filter.isDate)
            return [filter.field, filter.value]
          return [filter.field, this.extractDate(filter.value)]
        })
      );
    },
    makeQueryText(filters) {
      if (filters.length == 0) return {};
      const query = { $and: [] };
      for (const filter of filters) {
        query.$and.push(this.getValueFilterText(filter));
      }
      return query;
    },
    extractDate(value){
      const range= (rango)=>{
          return {$gte:  dateUtils.formatDate(rango.start, "YYYY-MM-DD HH:mm"), $lte:  dateUtils.formatDate(rango.end, "YYYY-MM-DD HH:mm") }
        }
      switch(value){
        case 'today':
          return range(dateUtils.getRangeOfToday());
        case 'yesterday':
          return range(dateUtils.getRangeOfYesterday())  
        case 'last_7_days':
          return range(dateUtils.getRangeLast7days())  
        case 'last_30_days':
          return range(dateUtils.getRangeLast30days())  
        default:
          return value
      }
    },
    getValueFilterText(filter) {
      const query = { $or: [] };    
      if (filter.text.length == 1) {
        if (filter.isArray) {
          return {
            [filter.field]: {
              $elemMatch: { [filter.subField]: { $regex: filter.text[0], $options: "i" } }
            }
          };
        } else {
          return { [filter.field]: { $regex: filter.text[0], $options: "i" } };
        }
      }
      for (const value of filter.text) {
        if (filter.isArray) {
          query.$or.push({
            [filter.field]: {
              $elemMatch: { [filter.subField]: { $regex: value, $options: "i" } }
            }
          });
        } else {
          query.$or.push({ [filter.field]: { $regex: value, $options: "i" } });
        }
      }
      return query;
    },    
    filtersByType(type) {
      return this.filters.filter((filter) => filter.type == type);
    },
    groupFilters(filters) {
      if (filters.length == 0) return [];
      return filters.reduce((acc, obj) => {
        const key = obj.field;
        let group = acc.find((group) => group.field === key); // Buscar grupo existente
        if (!group) {
          group = { field: obj.field, text: [obj.text] }; // Crear nuevo grupo si no existe
          acc.push(group);
        } else {
          group.text.push(obj.text); // Agregar valor "text" al grupo existente
        }
        return acc;
      }, []);
    },
  },
});
