<script setup>
import { computed, ref, onBeforeUpdate, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import { useUserStore } from "@/_store/userStore.js";
import { logoListStore } from "@/_store/logoStore";
import { notificationListStore } from "@/_store/notificationStore";

const notificationStore = notificationListStore();

const showMenu = ref(false);
const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const uploadStore = logoListStore();

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const currentRouteName = computed(() => {
  return route.name;
});

const currentDirectory = computed(() => {
  let path = route.path.split("/");
  path.pop();
  if (path.length == 1) return null;
  const dir = router.resolve(path.join("/"));
  return dir;
});
const toggleMenu = (event) => {
  event.stopPropagation();
  showMenu.value = !showMenu.value;
};

const handleClickOutside = (event) => {
  const dropdown = document.getElementById("dropdownMenuButton");
  if (dropdown && !dropdown.contains(event.target)) {
    showMenu.value = false;
  }
};
const navbarMinimize = () => store.commit("navbarMinimize");
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});
onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
const toggleNavigationOnMobile = () => {
  if (window.innerWidth < 1200) {
    navbarMinimize();
  }
};
function closeSession() {
  uploadStore.cleanLogo();
  notificationStore.stopIntervals();
  localStorage.removeItem("user");
  router.push({ name: "Signin Basic" });

}
onBeforeUpdate(() => {
  toggleNavigationOnMobile();
});
</script>
<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ms-2"
        :class="isRTL ? 'me-3' : ''"
      >
        <a
          href="#"
          class="p-0 nav-link text-body"
          @click.prevent="navbarMinimize"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!-- <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input type="text" class="form-control" :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'" />
          </div> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Mi Perfil' }"
              class="px-0 nav-link font-weight-bold"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              "
            >
              <i
                class="fa fa-user text-white"
                :class="[isRTL ? 'ms-sm-2' : 'me-sm-1']"
              ></i>
              <span class="d-sm-inline text-white d-none">{{
                userStore.userSigned != null ? userStore.userSigned.email : ""
              }}</span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-white"
              @click.prevent="navbarMinimize"
            >
              <div class="sidenav-toggler-inner">
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
              </div>
            </a>
          </li>
          <li
            class="px-3 nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link"
              :class="[
                isNavFixed && !darkMode ? 'opacity-8 text-dark' : 'text-white',
                showMenu ? 'show' : '',
              ]"
              aria-expanded="false"
              @click="toggleMenu"
            >
              <i class="text-white cursor-pointer fa fa-cog"></i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4 index"
              :class="{ show: showMenu }"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <a
                  class="dropdown-item border-radius-md z-i-2000"
                  href="javascript:;"
                  @click="router.push({ path: '/account/profile' })"
                >
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fas fa-user-circle me-3"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span>Mi Perfil</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  id="idclose"
                  class="dropdown-item border-radius-md z-i-2000"
                  href="javascript:;"
                  @click="closeSession"
                >
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fas fa-sign-out-alt me-3"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span>Cerrar Sesión</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
.index {
  z-index: 3000 !important;
}
.z-i-2000 {
  z-index: 9999 !important;
}
</style>