<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useUserStore } from "@/_store/userStore.js";
import { notificationListStore } from "@/_store/notificationStore";
// import { useStore } from "vuex";

/* import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue"; */
//import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import SidenavUpItem from "./SidenavUpItem.vue";

// const store = useStore();
const notificationStore = notificationListStore();
const userStore = useUserStore();

const isRTL = false;
const sidebarWidth = ref(0);
const resizeObserver = ref(null);
const sidebarRef = ref(null);
const emit = defineEmits(['resize']);

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};
const handleResize = (entries) => {
  for (let entry of entries) {
    nextTick(() => {
      sidebarWidth.value = entry.contentRect.width;
      emit('resize', sidebarWidth.value);
    });
  }
};

const debouncedHandleResize = debounce(handleResize, 300);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const getSecondRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return `/${routeArr[1]}/${routeArr[2]}`;
};
onMounted(() => {
  resizeObserver.value = new ResizeObserver(debouncedHandleResize);
  if (sidebarRef.value) {
    resizeObserver.value.observe(sidebarRef.value);
  }
  notificationStore.startIssueAlertInterval();
});
onBeforeUnmount(() => {
  if (resizeObserver.value && sidebarRef.value) {
    resizeObserver.value.unobserve(sidebarRef.value);
  }

});
function initialLetter(text) {
  if (sidebarWidth.value <= 96) {
    return text[0];
  } else {
    return text;
  }
}
</script>
<template>
  <div
    ref="sidebarRef"
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-up-item
          :to="{ name: 'Dashboard' }"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :nav-text="isRTL ? 'لوحة القيادة' : 'Dashboard'"
        >
          <template #icon>
            <i class="ni ni-tv-2 text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>

      <li class="nav-item">
        <sidenav-up-item
          to="/shipments"
          :class="getRoute() === 'shipments' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Envíos'"
        >
          <template #icon>
            <i
              class="ni ni-delivery-fast text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>  
      <li class="nav-item" v-if="userStore.userSigned?.portalType != 'AGENCY'">
        <sidenav-up-item
          to="/own-pickup"
          :class="getRoute() === 'own-pickup' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Recogidas'"
        >
          <template #icon>
            <i
              class="fa fa-circle-arrow-right text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item">
        <i 
          v-if="notificationStore.issueNotification" v-tooltip 
          data-bs-title="Tienes nuevos mensajes sin leer, en los últimos 7 días" class="ni ni-bell-55 text-sm text-primary notification-alert">
        </i>
        <sidenav-up-item
          to="/issues"
          :class="getRoute() === 'issues' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Incidencias'"
        >
          <template #icon>
            <i
              class="ni ni-settings-gear-65 text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li v-if="userStore.userSigned?.portalType === 'AGENCY'" class="nav-item">
        <sidenav-up-item
          to="/customers"
          :class="getRoute() === 'customers' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Clientes'"
        >
          <template #icon>
            <i class="ni ni-circle-08 text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item">
        <sidenav-up-item
          to="/contacts"
          :class="getRoute() === 'contacts' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Contactos'"
        >
          <template #icon>
            <i class="ni ni-badge text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li
        class="mt-3 nav-item nav-section"
        v-if="userStore.userSigned?.portalType === 'AGENCY'"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          {{ initialLetter("RECOGIDAS") }}
        </h6>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/pickup/own"
          :class="getSecondRoute() === '/pickup/own' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Propias'"
        >
          <template #icon>
            <i
              class="fa fa-circle-arrow-right text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/pickup/others"
          :class="getSecondRoute() === '/pickup/others' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Otras Plazas'"
        >
          <template #icon>
            <i
              class="fa fa-circle-arrow-right text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li
        class="mt-3 nav-item nav-section"
        v-if="userStore.userSigned?.portalType === 'AGENCY'"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          {{ initialLetter("ENTREGAS") }}
        </h6>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/delivery/own"
          :class="getSecondRoute() === '/delivery/own' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Propias'"
        >
          <template #icon>
            <i
              class="fa fa-circle-arrow-left text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li
        class="mt-3 nav-item nav-section"
        v-if="userStore.userSigned?.portalType === 'AGENCY'"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          {{ initialLetter("VALORACIÓN") }}
        </h6>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/pricing/assesment"
          :class="getSecondRoute() === '/pricing/assesment' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Tarifas'"
        >
          <template #icon>
            <i class="fa fa-money-bill text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/pricing/prices"
          :class="getSecondRoute() === '/pricing/prices' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Tarifas Precios'"
        >
          <template #icon>
            <i class="fa fa-money-bill text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/pricing/concepts"
          :class="getSecondRoute() === '/pricing/concepts' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Suplementos'"
        >
          <template #icon>
            <i class="fa fa-money-bill text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType === 'AGENCY'">
        <sidenav-up-item
          to="/pricing/valuations"
          :class="getSecondRoute() === '/pricing/valuations' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Valoraciones'"
        >
          <template #icon>
            <i class="fa fa-money-bill text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li
        class="mt-3 nav-item nav-section"
        v-if="userStore.userSigned?.portalType != 'AGENCY'"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          {{ initialLetter("MARKETPLACE") }}
        </h6>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType != 'AGENCY'">
        <sidenav-up-item
          to="/marketplace/products"
          :class="getSecondRoute() === '/marketplace/products' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Productos'"
        >
          <template #icon>
            <i class="fa fa-box text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType != 'AGENCY'">
        <sidenav-up-item
          to="/marketplace/orders"
          :class="getSecondRoute() === '/marketplace/orders' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Pedidos'"
        >
          <template #icon>
            <i
              class="fa fa-shopping-cart text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item" v-if="userStore.userSigned?.portalType != 'AGENCY'">
        <sidenav-up-item
          to="/marketplace/synchronization"
          :class="getSecondRoute() === 'synchronization' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Sincronización'"
        >
          <template #icon>
            <i class="fa fa-sync text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="mt-3 nav-item nav-section">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          {{ initialLetter("IMPORTACIONES") }}
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-up-item
          to="/imports/shipments"
          :class="getSecondRoute() === '/imports/shipments' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Envios'"
        >
          <template #icon>
            <i
              class="ni ni-cloud-upload-96 text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li v-if="userStore.userSigned?.portalType === 'AGENCY'" class="nav-item">
        <sidenav-up-item
          to="/imports/customers"
          :class="getSecondRoute() === '/imports/customers' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Clientes'"
        >
          <template #icon>
            <i
              class="ni ni-cloud-upload-96 text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="nav-item">
        <sidenav-up-item
          to="/imports/contacts"
          :class="getSecondRoute() === '/imports/contacts' ? 'active' : ''"
          :nav-text="isRTL ? 'الجداول' : 'Contactos'"
        >
          <template #icon>
            <i
              class="ni ni-cloud-upload-96 text-secondary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-up-item>
      </li>
      <li class="mt-3 nav-item nav-section">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          {{ initialLetter("CUENTA") }}
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-up-item
          to="/account/profile"
          :class="getRoute() === 'account/profile' ? 'active' : ''"
          :nav-text="isRTL ? 'حساب تعريفي' : 'Mi Perfil'"
        >
          <template #icon>
            <i class="ni ni-single-02 text-sm text-secondary opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>

      <li class="nav-item">
        <sidenav-up-item
          to="/account/security"
          :class="getRoute() === 'account/security' ? 'active' : ''"
          :nav-text="isRTL ? 'حساب تعريفي' : 'Seguridad'"
        >
          <template #icon>
            <i class="ni ni-key-25 text-sm text-secondary opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>

      <li
        v-if="
          userStore.userSigned?.portalType === 'AGENCY' ||
          userStore.userSigned?.portalType === 'ECOM'
        "
        class="nav-item"
      >
        <sidenav-up-item
          to="/account/users"
          :class="getRoute() === 'account/users' ? 'active' : ''"
          :nav-text="isRTL ? 'حساب تعريفي' : 'Usuarios'"
        >
          <template #icon>
            <i class="ni ni-single-02 text-sm text-secondary opacity-10"></i>
          </template>
        </sidenav-up-item>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.nav-item{
  max-height: 30px !important;
}
.nav-section{
  h6{
    margin-bottom: 0px;
  }
}
.navbar-vertical.navbar-expand-xs{
  overflow-y: hidden !important;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse{
  overflow: hidden !important;
}
.notification-alert{
  right: 42%;
  position: absolute;
  display: flex;
  align-items: end;
  padding-top: 3%;
}
</style>
