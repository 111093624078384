<script setup>
import { ref, watch } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";

let model = ref({
  ctt_sender: ""
});

let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      ctt_sender: "",
      seur_sender: "",
      cex_sender: "",
    }),
  }
});

let emit = defineEmits(["update:modelValue"]);

watch(
  () => model.value,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

model.value = props.modelValue ?? { ctt_sender: "" };
</script>
<template>
  <div role="form" class="p-1">
    <div class="row">
      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-3">
              <label class="col-form-label">Cuenta Ctt</label>
            </div>
            <div class="col-3">
              <argon-input id="integration_ctt" v-model="model.ctt_sender" :max-length="10" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-3">
              <label class="col-form-label">Cuenta Seur</label>
            </div>
            <div class="col-3">
              <argon-input id="integration_seur" v-model="model.seur_sender" :max-length="7" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-3">
              <label class="col-form-label">Cuenta Cex</label>
            </div>
            <div class="col-3">
              <argon-input id="integration_seur" v-model="model.cex_sender" :max-length="9" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>