<script setup>

import { ArgonLoadingButton } from "@/components"
import { watch,ref, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { shipmentDetailStore } from "@/_store";
import ModalBase from "@/components/modal/ModalBase.vue";
const emit = defineEmits(["successChange", 'close']);
const detailStore = shipmentDetailStore();
const modalChangeShipmentStatus = ref(null);
import { useStore } from "vuex";
const loading = ref(false);
const store = useStore();

const bootstrap = store.state.bootstrap;
const props = defineProps({
  shipmentId: {
    type: String,
    required: true,
  },
  options:{
    type: Array,
    default(){
      return []
    }
  }
});
const rules = {
  status: { required: helpers.withMessage("Estado es requerido", required)},
  description: { required: helpers.withMessage("Descripción es requerido", required)}
};
const data = ref({
  status: "",
  description: ""
})
const v$ = ref(useVuelidate(rules, data));

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
onMounted(() => {
  let statusModal = new bootstrap.Modal(document.getElementById("modalChangeShipmentStatusId"), {
    keyboard: true,
  });
    statusModal.show();
})
function submit() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message= "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      loading.value=true;
      detailStore.changeStatusShipment(props.shipmentId,data.value)
    }
  });
}
let key= ref(0)
function cleanData(){
  data.value={
    status: "",
    description:""
  }
  v$.value.$reset()
  key.value++
  emit("close")
}
watch(
  () => detailStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value=false;
    }
  }
);
watch(
  () => detailStore.shipmentStatusChange,
  (value) => {
    if (value) {
      loading.value=false;
      emit("successChange");
      modalChangeShipmentStatus.value.closeModal();
    }
  }
);
</script>

<template>
  <modal-base id="modalChangeShipmentStatusId" ref="modalChangeShipmentStatus" @hide="cleanData()">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">
        Transitar Estado
      </h6>
      <i class="fas tags ms-3"></i>
    </template>
    <template #body>
      <form class="border-0">
        <div>
          <argon-select id="user-type" placeholder="Estado" :key="key" v-model="data.status" name="user-type" :is-required="true"
            :options="props.options" :error="v$.status.$error">
          </argon-select>
        </div>
          <div>
            <argon-input id="description" placeholder="Descripción" v-model="data.description" :max-length="75"
            type="text" :error="v$.description.$error" />
        </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button
        class="btn bg-gradient-primary btn-sm"
        color="primary"
        variant="gradient"
        :label="loading ? 'Enviando...' : 'Enviar'"
        :loading="loading"
        :disable-button="loading"
        @click="submit"
      >
      </argon-loading-button>
    </template>
    <div class="position-fixed top-1 end-1 ">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>
<style lang="scss">
.file-preview-container {
  gap: 1rem;
}
.file-input-label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-input-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 80px;
  margin-right: 80px;
}
.fa-upload {
  font-size: 20px;
  color: #67748e;
}

</style>