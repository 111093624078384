<script setup>
import Direction from "@/components/forms/Direction.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { ref, watch, onMounted } from 'vue';
import { dateUtils } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email, requiredIf } from "@vuelidate/validators";

const localValue = ref({
  name: "",
  contact: "",
  phone: "",
  email:"",
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD HH:mm"),
  schedules: {
    initial: "",
    final: "",
  },
  instructions: "",
  address: {
    postal_code: "",
    street: "",
    street2: "",
    city: "",
    country: "ES",
    location: {
      lat: 0,
      lng: 0,
    },
    text: "",
    place_id: "",
    normalized: true,
    province: "",
  },
});

const emit = defineEmits(["update:modelValue"]);
const directionKey = ref(Date.now());
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  },
  courier: {
    type: String,
    default: "",
  },
});

async function validateChangeAddress() {
  v$.value.$touch();
  await v$.value.$validate();
  return v$.value.$errors;
}
const checkHoraMayor = () => {
  let inicioInt = horaToInt(localValue.value.schedules.initial);
  let finalInt = horaToInt(localValue.value.schedules.final);
  if (inicioInt == 0 || finalInt == 0) return true;
  if (inicioInt >= finalInt) return false;
  return true;
};

const rules = {
  name: {
    required: helpers.withMessage("Por favor ingrese un nombre.", required),
  },
  contact: {
    required: helpers.withMessage("Por favor ingrese un contacto.", required),
  },
  phone: {
    required: helpers.withMessage("Por favor ingrese un teléfono.", required),
  },
  email: {
    required: helpers.withMessage("Email es requerido", requiredIf(props.courier=='CTT')), email: helpers.withMessage("Ingrese un email valido", email) 
  },
  date: {
    required: helpers.withMessage("Por favor ingrese una fecha.", required,  requiredIf(props.courier!='SEUR')),
  },
  schedules: {
    initial: {
      required: helpers.withMessage("Por favor ingrese el horario de inicio.",  requiredIf(props.courier=='CEX')),
    },
    final: {
      required: helpers.withMessage("Por favor ingrese el horario de fin.", requiredIf(props.courier=='CEX')),
      checkHoraMayor: helpers.withMessage("La hora fin debe de ser mayor a la inicial.", checkHoraMayor),
    },
  },
};

const v$ = useVuelidate(rules, localValue);
function horaToInt(value) {
  if (value == "") return 0;
  if (!value.includes(":")) return 0;
  let hora = parseInt(value.split(":")[0]) * 3600;
  let minutos = parseInt(value.split(":")[1]) * 60;
  return hora + minutos;
}

function resetModel() {
  localValue.value.name = "";
  localValue.value.contact = "";
  localValue.value.phone = "";
  localValue.value.email = "";
  localValue.value.date = dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD");
  localValue.value.schedules.initial = "";
  localValue.value.schedules.final = "";
  localValue.value.instructions = "";
  localValue.value.address.postal_code = "";
  localValue.value.address.street = "";
  localValue.value.address.street2 = "";
  localValue.value.address.city = "";
  localValue.value.address.country = "ES";
  localValue.value.address.location.lat = 0;
  localValue.value.address.location.lng = 0;
  localValue.value.address.text = "";
  localValue.value.address.place_id = "";
  localValue.value.address.normalized = true;
  localValue.value.address.province = "";
  
  v$.value.$reset();
  directionKey.value = Date.now();
}


onMounted(() => {
  localValue.value = props.modelValue;
});

watch(
  () => localValue.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true }
);

defineExpose({
  validateChangeAddress,
  resetModel
});
</script>

<template>
  <div>
    <div class="row">
      <div class="col-2">
        <label class="col-form-label required">Dirección</label>
      </div>
      <div class="col-10">
        <direction :key="directionKey" v-model="localValue.address" />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label required">Nombre</label>
          </div>
          <div class="col-8">
            <argon-input id="name" :error="v$.name.$error" v-model="localValue.name" :max-length="30" type="text" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label required">Contacto</label>
          </div>
          <div class="col-9">
            <argon-input id="contact" :error="v$.contact.$error" v-model="localValue.contact" :max-length="30" type="text" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label required">Teléfono</label>
          </div>
          <div class="col-8">
            <argon-input id="phone" :error="v$.phone.$error" v-model="localValue.phone" :max-length="30" type="text" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3">
          <label class="col-form-label" :class="{ required: courier == 'CTT' }">Email</label>
        </div>
        <div class="col-9">
          <argon-input
            id="email"
            v-model="localValue.email"
            :error="v$.email.$error"
            :max-length="75"
            type="email"
            placeholder=""
          />
        </div>
        </div>
      </div>
    </div>
    
    <div class="row" v-if="courier!='SEUR'">      
      <div  class="col-4">
        <div class="row">
          <div class="col-6">
            <label class="col-form-label required">Fecha</label>
          </div>
          <div class="col-6">
            <argon-input id="date" :error="v$.date.$error" v-model="localValue.date" type="date" />
          </div>
        </div>
      </div>       
      <div v-if="courier=='CEX'" class="col-4">
        <div class="row">
          <div class="col-6">
            <label class="col-form-label required">Horario inicio</label>
          </div>
          <div class="col-6">
            <argon-input v-model="localValue.schedules.initial" :error="v$.schedules.initial.$error" type="time" />
          </div>
        </div>
      </div>
      <div v-if="courier=='CEX'" class="col-4">
        <div class="row">
          <div class="col-6">
            <label class="col-form-label required">Horario fin</label>
          </div>
          <div class="col-6">
            <argon-input v-model="localValue.schedules.final" :error="v$.schedules.final.$error" type="time" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="courier!='CTT'" class="row">
      <div class="col-2">
        <label class="col-form-label">Observaciones</label>
      </div>
      <div class="col-10">
        <argon-input v-model="localValue.instructions" type="text" />
      </div>
    </div>
  </div>
</template>
