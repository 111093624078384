import { defineStore } from 'pinia';
import { stopService } from '@/_services';


export const ownDeliveryListStore = defineStore('ownDeliveryList', {
  state: () => ({ 
    reload: false,
    ownDeliveries: null,
    error: null,
    ownDeliveriesAsExcel: null,
    customFilterDate: null
  }),
  actions: {
    setCustomFilterDate(value){
      this.customFilterDate= value;
    },
    getOwnDeliveries(pagination, filters, sort) {
      this.reload = false
      stopService.getAllOwnDeliveries(pagination, filters, sort)
        .then((results) => this.ownDeliveries = results)
        .catch((reason) => this.error = reason);
    },
    getOwnDeliveriesAsExcel(query, sort){
      query.courier='PROPIO'
      query.type='ENTREGA'
      stopService.getStopAsExcel(query, sort)
      .then((results) => this.ownDeliverIEsAsExcel = results)
      .catch((reason) => this.error = reason);
    }
  },
})
