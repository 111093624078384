import createPublicAxios from "./axiosHttp";

function getTracking(request) {
  const axios = createPublicAxios();
  let urlPath = `/tracking/${request.type}/${request.number}`;
  if( request.customer_id){
    urlPath += `?customer_id=${request.customer_id}`
  }
  return axios.get(urlPath);
}

export const trackingService = {
  getTracking,
};
